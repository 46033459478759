import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const ripple = keyframes`
  0% {
  transform: scaleX(0);
  opacity: 0.5;
  }
  20% {
  transform: scaleX(1);
  opacity: 0.3;
  }
  100% {
  transform: scaleX(1);
  opacity: 0;
  }
`;

const SCButton = styled.button`
  color: ${({ theme }) => theme.colors.$white};
  border: 0 none;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 500;
  padding: ${({ padding }) => padding || '1.2rem 4rem'};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(props) => (props.width ? `${props.width}` : `auto`)};
  box-shadow: 0 1px 7px 0 rgb(0 0 0 / 20%);

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  letter-spacing: 0.5px;
  background: ${(props) => (props.disabled ? `${props.theme.colors.$gray}` : `${props.theme.colors.$theme}`)};
  ${({ onlyIcon, theme }) =>
    onlyIcon &&
    `
    padding: 0;
  `}
  ${({ bordered, theme }) =>
    bordered === true &&
    `
    color: ${theme.colors.$theme};
    background: transparent;
    border: 1px solid ${theme.colors.$theme};
  `}

  ${({ isSmall, theme }) =>
    isSmall === true &&
    `
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 1rem 2rem;
  `}

  ${({ colorTheme, theme }) =>
    colorTheme === 'ready' &&
    `
    background: ${theme.colors.$ready};
  `}

  ${({ colorTheme, theme, bordered }) =>
    bordered === true && colorTheme === 'danger'
      ? `
  border: 1px solid ${theme.colors.$danger};
  background: transparent;
  color: ${theme.colors.$danger};
`
      : colorTheme === 'danger' && bordered === false
      ? `
background: ${theme.colors.$danger};
`
      : null}

  ${({ colorTheme, theme }) =>
    colorTheme === 'go' &&
    `
    background: ${theme.colors.$success};
  `}

  ${({ colorTheme, theme, disabled }) =>
    colorTheme === 'ready' &&
    disabled &&
    `
      background: ${theme.colors.$gray};
    `}

    ${({ colorTheme, theme, disabled }) =>
      colorTheme === 'go' &&
      disabled &&
      `
      background: ${theme.colors.$gray};
    `}



  & span {
    position: relative;
    z-index: 1;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background: ${(props) => (props.disabled ? 'transparent' : `${props.theme.colors.$theme}`)};
    transition: all 1s ease-in-out;
  }
  &:hover {
    &:before {
      animation: ${ripple} 2s;
    }
    &:before {
      animation: ${ripple} 2s;
    }
  }
  & .btn_icon {
    display: block;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    margin-right: 1rem;
    ${({ onlyIcon, disabled, theme }) =>
      onlyIcon &&
      `
    margin-right: 0;
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    padding: 5px;
    border-radius: 4px;
    background: ${theme.colors.$white};
    border: 2px solid;
    border-color: ${disabled ? `${theme.colors.default_gray}` : `${theme.colors.$theme}`};
    & img {
      max-height: 100%;
    }
  `}
  }

  ${({ btnType, disabled, theme }) =>
    btnType === 'secondary' &&
    `
    color: ${disabled ? `${theme.colors.$white}` : `${theme.colors.$white}`};
    background: ${disabled ? `${theme.colors.$gray}` : `${theme.colors.$ready}`};
  `}
  
  ${({ btnType, disabled, theme }) =>
    btnType === 'secondaryBordered' &&
    `
  color: ${disabled ? `${theme.colors.$white}` : `${theme.colors.$ready}`};
  background: ${disabled ? `${theme.colors.$gray}` : `transparent`};
  border: 1px solid ${disabled ? `${theme.colors.$gray}` : `${theme.colors.$ready}`};
`}

  ${({ btnType, disabled, theme }) =>
    btnType === 'textOnly' &&
    `
    color: ${disabled ? `${theme.colors.$gray}` : `${theme.colors.$text}`};
    background: transparent;
    font-weight: 500;
    box-shadow: none;
    &:before {
        display: none;
  }
  `}
  &.outline {
    background: transparent;
    color: ${({ theme }) => theme.colors.$theme};
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.$theme};
    margin-right: 2rem;
  }
  }
`;

// ${({ theme }) => theme.colors.$theme}
const Button = (props) => {
  const {
    name,
    btnType,
    id,
    extraclass,
    onButtonClick,
    children,
    onlyIcon,
    customStyle,
    isDisabled,
    title,
    width,
    type,
    colorTheme,
    bordered,
    isSmall,
    ...rest
  } = props;
  return (
    <SCButton
      className={`${extraclass}`}
      name={name}
      id={id}
      onClick={onButtonClick}
      type={type}
      width={width}
      btnType={btnType}
      onlyIcon={onlyIcon}
      style={customStyle}
      disabled={isDisabled}
      title={`${title ? `${title}` : ''}`}
      colorTheme={colorTheme}
      bordered={bordered}
      isSmall={isSmall}
      {...rest}
    >
      {children && <span className="btn_icon">{children}</span>}
      {onlyIcon ? null : <span>{name}</span>}
    </SCButton>
  );
};

Button.propTypes = {
  name: PropTypes.string,
  btnType: PropTypes.string,
  id: PropTypes.string,
  extraclass: PropTypes.string,
  children: PropTypes.any,
  onButtonClick: PropTypes.func.isRequired,
  onlyIcon: PropTypes.bool,
  customStyle: PropTypes.object,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  colorTheme: PropTypes.string,
  bordered: PropTypes.bool,
  isSmall: PropTypes.bool,
};

Button.defaultProps = {
  name: 'Cta',
  btnType: 'default',
  id: '',
  extraclass: '',
  onlyIcon: false,
  children: null,
  customStyle: {},
  isDisabled: false,
  title: '',
  type: 'button',
  width: '',
  colorTheme: '',
  bordered: false,
  isSmall: false,
};

export default Button;

import {
  FETCH_TRADER_STRATEGIES_SUCCESS,
  FETCH_TRADER_STRATEGY_INFO_SUCCESS,
  FETCH_TRADERS_ACTIVE_CALLS_SUCCESS,
  FETCH_TRADERS_PAST_CALLS_SUCCESS,
} from './types';

export const traderDashboardReducer = (
  state = {
    traderStrategies: null,
    traderStrategiesLoaded: false,
    traderStrategyInfo: null,
    traderStrategyInfoLoaded: false,
    tradersActiveCalls: null,
    tradersActiveCallsLoaded: false,
    tradersPastCalls: null,
    tradersPastCallsLoaded: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TRADER_STRATEGIES_SUCCESS: {
      return {
        ...state,
        traderStrategies: action.payload,
        traderStrategiesLoaded: true,
      };
    }

    case FETCH_TRADER_STRATEGY_INFO_SUCCESS: {
      return {
        ...state,
        traderStrategyInfo: action.payload,
        traderStrategyInfoLoaded: true,
      };
    }

    case FETCH_TRADERS_ACTIVE_CALLS_SUCCESS: {
      return {
        ...state,
        tradersActiveCalls: action.payload,
        tradersActiveCallsLoaded: true,
      };
    }

    case FETCH_TRADERS_PAST_CALLS_SUCCESS: {
      return {
        ...state,
        tradersPastCalls: action.payload,
        tradersPastCallsLoaded: true,
      };
    }

    default:
      return state;
  }
};

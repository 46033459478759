import { createPromiseAction } from '@adobe/redux-saga-promise';

import {
  ALLCARDS,
  ALLCARDS_SUCCESS,
  REJECT_CARD,
  REJECT_CARD_SUCCESS,
  SEND_IN_REVIEW,
  PUBLISH_CARD,
  CREATE_CARD,
  ALLCARDS_RESET,
  CREATE_CARD_SUCCESS,
  SEND_IN_REVIEW_SUCCESS,
  PUBLISH_CARD_SUCCESS,
  FETCH_SUGGESTIONS,
  FETCH_SUGGESTIONS_SUCCESS,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS,
  FETCH_AUTHOR_PROFILE,
  FETCH_AUTHOR_PROFILE_SUCCESS,
  FETCH_AUTHORS_LIST,
  FETCH_AUTHORS_LIST_SUCCESS,
  FETCH_CURRENT_USER_PROFILE,
  FETCH_CURRENT_USER_PROFILE_SUCCESS,
  DELETE_CARD,
  DELETE_CARD_SUCCESS,
  DELETE_CARD_FAILURE,
  APPLY_FILTER,
  BOOST_CARDS,
  FETCH_TAG_LIST,
  FETCH_TAG_LIST_SUCCESS,
  CREATE_TAG,
  EDIT_CARD_SUCCESS,
  ADD_USER_TO_PRO,
} from './types';

export const getAllCards = (payload) => ({
  type: ALLCARDS,
  payload,
});

export const getAllCardsSuccess = (payload) => ({
  type: ALLCARDS_SUCCESS,
  payload,
});

export const resetAllCards = () => ({
  type: ALLCARDS_RESET,
});

export const createCard = (payload) => ({
  type: CREATE_CARD,
  payload,
});

export const createCardSuccess = (payload) => ({
  type: CREATE_CARD_SUCCESS,
  payload,
});

export const editCardSuccess = (payload) => ({
  type: EDIT_CARD_SUCCESS,
  payload,
});

export const rejectCardSuccess = (payload) => ({
  type: REJECT_CARD_SUCCESS,
  payload,
});

export const sendInReviewSuccess = (payload) => ({
  type: SEND_IN_REVIEW_SUCCESS,
  payload,
});

export const publishCardSuccess = (payload) => ({
  type: PUBLISH_CARD_SUCCESS,
  payload,
});

export const fetchSuggestions = createPromiseAction(FETCH_SUGGESTIONS);

export const fetchSuggestionsSuccess = (payload) => ({
  type: FETCH_SUGGESTIONS_SUCCESS,
  payload,
});

export const fetchTopics = () => ({
  type: FETCH_TOPICS,
});

export const fetchTopicsSuccess = (payload) => ({
  type: FETCH_TOPICS_SUCCESS,
  payload,
});

export const fetchAuthorProfile = (payload) => ({
  type: FETCH_AUTHOR_PROFILE,
  payload,
});

export const fetchAuthorProfileSuccess = (payload) => ({
  type: FETCH_AUTHOR_PROFILE_SUCCESS,
  payload,
});

export const fetchAuthorsListSuccess = (payload) => ({
  type: FETCH_AUTHORS_LIST_SUCCESS,
  payload,
});

export const fetchCurrentUserProfile = () => ({
  type: FETCH_CURRENT_USER_PROFILE,
});

export const fetchCurrentUserProfileSuccess = (payload) => ({
  type: FETCH_CURRENT_USER_PROFILE_SUCCESS,
  payload,
});

export const deleteCard = (payload) => ({
  type: DELETE_CARD,
  payload,
});

export const deleteCardSuccess = (payload) => ({
  type: DELETE_CARD_SUCCESS,
  payload,
});

export const deleteCardFailure = (payload) => ({
  type: DELETE_CARD_FAILURE,
  payload,
});

export const fetchTagListSuccess = (payload) => ({
  type: FETCH_TAG_LIST_SUCCESS,
  payload,
});

export const rejectCard = createPromiseAction(REJECT_CARD);

export const sendInReview = createPromiseAction(SEND_IN_REVIEW);

export const publishCard = createPromiseAction(PUBLISH_CARD);

export const fetchAuthorsList = createPromiseAction(FETCH_AUTHORS_LIST);

export const applyFilters = createPromiseAction(APPLY_FILTER);

export const sendBoostCards = createPromiseAction(BOOST_CARDS);

export const fetchTagList = createPromiseAction(FETCH_TAG_LIST);

export const createTag = createPromiseAction(CREATE_TAG);

export const addUserToPro = createPromiseAction(ADD_USER_TO_PRO);

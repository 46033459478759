export const urls = {
  transferFund: () => `/api/v1/admin/exchange/exchange/transfer-funds`,
  getConnectedKeys: ({ userID }) => `/api/v1/admin/exchange/key-connections?userId=${userID}`,
  subscribeStrategy: () => `/api/v1/admin/pro/subscription`,
  unsubscribeStrategy: ({ strategyId, userId }) => `/api/v1/admin/pro/${strategyId}/unsubscribe?userId=${userId}`,
  getProStrategies: () => `/api/v1/admin/strategies`,
  getProSubscriptionDetails: ({ userId, live }) => `/api/v1/admin/pro/subscriptions?userId=${userId}&live=${live}`,
  fetchProUserProfile: ({ userId }) => `/api/v1/admin/userprofile/${userId}`,
  updateActiveStatus: ({ strategyId, flag, userId }) =>
    `/api/v1/admin/pro/strategy/${strategyId}/pause/${flag}?userId=${userId}`,
  updateDeployedAmount: ({ strategyId, userId, amount }) =>
    `/api/v1/admin/pro/strategy/${strategyId}/deployed-amount?userId=${userId}&amount=${amount}`,
  getProSummary: ({ userId }) => `/api/v1/admin/pro/summary?userId=${userId}`,
  getProUserPositions: ({ strategyId, userId }) =>
    `/api/v1/admin/pro/strategy/${strategyId}/positions?userId=${userId}`,
  updateLeverageMargin: ({ strategyId, code, userId, symbols, marginType, leverage }) =>
    `/api/v1/admin/pro/${code}/leverage-margin?userId=${userId}&strategyId=${strategyId}&symbol=${symbols}&marginType=${marginType === 'FOLLOW_TRADER' ? '' : marginType
    }&leverage=${leverage === 'FOLLOW_TRADER' ? '' : leverage}`,

  fetchProUsersList: () => `/api/v1/admin/pro/users`,
  getAumHistoryData: ({ ssid }) => `api/v1/admin/pro/subscription/${ssid}/changes`,
  getStrategiesTotalReturnsData: ({ userId }) => `/api/v1/admin/pro/temp/category/returns?userId=${userId}`,
  fetchSipStrategyPortfolio: ({ strategyId, userId }) =>
    `/api/v1/admin/pro/strategy/${strategyId}/portfolio?userId=${userId}`,
  fetchProStrategyAvailableBalance: ({ userId, keyType }) => `/api/v1/admin/pro/strategy/available-balance?userId=${userId}&keyType=${keyType}`,
  downloadDepositTransferHistory: ({ userId }) => `/api/v1/admin/exchange/binance/deposit-transfer-history?userId=${userId}`,
  fetchHmmSummary: ({ userId }) => `/api/v1/admin/hmm/summary?userId=${userId}`,
  fetchMonthlyReports: ({ userId }) => `/api/v1/admin/pro/gains-summary/months?userId=${userId}`,
  fetchCreditsCalculation: ({ userId }) => `/api/v1/admin/credits/calculation?userId=${userId}`,
  exchangeRefresh: ({ account }) => `/api/v1/admin/exchange/refresh?account=${account}`,
  fetchMonthlyReportDetails: ({ userId, monthCode }) =>
    `/api/v1/admin/pro/gains-summary?userId=${userId}&monthCode=${monthCode}`,

  redeemSmartCash: () => `/api/v1/admin/smart-cash/redeem`,
  investSmartCash: () => `/api/v1/admin/smart-cash/invest`,
};

import { createPromiseAction } from '@adobe/redux-saga-promise';

import {
  PRO_STRATEGIES,
  PRO_STRATEGIES_SUCCESS,
  PRO_SUBSCRIPTION_DETAILS,
  PRO_SUBSCRIPTION_DETAILS_SUCCESS,
  PRO_PAST_SUBSCRIPTION_DETAILS,
  PRO_PAST_SUBSCRIPTION_DETAILS_SUCCESS,
  SUBSCRIBE_STRATEGY,
  SUBSCRIBE_STRATEGY_SUCCESS,
  TRANSFER_FUNDS,
  TRANSFER_FUNDS_SUCCESS,
  UNSUBSCRIBE_STRATEGY,
  UNSUBSCRIBE_STRATEGY_SUCCESS,
  FETCH_PRO_USER_PROFILE,
  FETCH_PRO_USER_PROFILE_SUCCESS,
  UPDATE_ACTIVE_STATUS,
  UPDATE_ACTIVE_STATUS_SUCCESS,
  UPDATE_DEPLOYED_AMOUNT,
  UPDATE_DEPLOYED_AMOUNT_SUCCESS,
  GET_PRO_SUMMARY,
  GET_PRO_SUMMARY_SUCCESS,
  GET_PRO_USER_POSITIONS,
  GET_PRO_USER_POSITIONS_SUCCESS,
  UPDATE_LEVERAGE_MARGIN,
  UPDATE_LEVERAGE_MARGIN_SUCCESS,
  FETCH_PRO_USERS_LIST,
  FETCH_PRO_USERS_LIST_SUCCESS,
  RESET_PRO_SUBSCRIPTION_DETAILS,
  GET_AUM_HISTORY_DATA,
  GET_AUM_HISTORY_DATA_SUCCESS,
  GET_STRATEGIES_TOTAL_RETURNS,
  GET_STRATEGIES_TOTAL_RETURNS_SUCCESS,
  FETCH_SIP_STRATEGY_PORTFOLIO,
  FETCH_SIP_STRATEGY_PORTFOLIO_SUCCESS,
  FETCH_HMM_SUMMARY,
  FETCH_HMM_SUMMARY_SUCCESS,
  FETCH_MONTHLY_REPORTS,
  FETCH_MONTHLY_REPORTS_SUCCESS,
  FETCH_CREDITS_CALCULATION_SUCCESS,
  FETCH_CREDITS_CALCULATION,
  EXCHANGE_REFRESH,
  EXCHANGE_REFRESH_SUCCESS,
  DOWNLOAD_DEPOSIT_HISTORY,
  DOWNLOAD_DEPOSIT_HISTORY_SUCCESS,
  RESET_MONTHLY_REPORT_DETAILS,
  FETCH_MONTHLY_REPORT_DETAILS,
  FETCH_MONTHLY_REPORT_DETAILS_SUCCESS,
  REDEEM_SMART_CASH,
  REDEEM_SMART_CASH_SUCCESS,
  INVEST_SMART_CASH,
  INVEST_SMART_CASH_SUCCESS,
  GET_CONNECTED_KEYS,
  GET_CONNECTED_KEYS_SUCCESS,
  GET_AVAILABLE_BALANCE_FOR_STRATEGY,
  GET_AVAILABLE_BALANCE_FOR_STRATEGY_SUCCESS,
} from './types';

export const getProStrategies = createPromiseAction(PRO_STRATEGIES);

export const getProStrategiesSuccess = (payload) => ({
  type: PRO_STRATEGIES_SUCCESS,
  payload,
});

export const resetProSubscriptionDetails = (payload) => ({
  type: RESET_PRO_SUBSCRIPTION_DETAILS,
  payload,
});

export const getProSubscriptionDetails = createPromiseAction(PRO_SUBSCRIPTION_DETAILS);

export const getProSubscriptionDetailsSuccess = (payload) => ({
  type: PRO_SUBSCRIPTION_DETAILS_SUCCESS,
  payload,
});

export const getProPastSubscriptionDetails = createPromiseAction(PRO_PAST_SUBSCRIPTION_DETAILS);

export const getProPastSubscriptionDetailsSuccess = (payload) => ({
  type: PRO_PAST_SUBSCRIPTION_DETAILS_SUCCESS,
  payload,
});

export const subscribeStrategy = createPromiseAction(SUBSCRIBE_STRATEGY);

export const subscribeStrategySuccess = (payload) => ({
  type: SUBSCRIBE_STRATEGY_SUCCESS,
  payload,
});

export const unsubscribeStrategy = createPromiseAction(UNSUBSCRIBE_STRATEGY);

export const unsubscribeStrategySuccess = (payload) => ({
  type: UNSUBSCRIBE_STRATEGY_SUCCESS,
  payload,
});

export const transferFund = createPromiseAction(TRANSFER_FUNDS);

export const transferFundSuccess = (payload) => ({
  type: TRANSFER_FUNDS_SUCCESS,
  payload,
});

export const fetchProUserProfile = createPromiseAction(FETCH_PRO_USER_PROFILE);

export const fetchProUserProfileSuccess = (payload) => ({
  type: FETCH_PRO_USER_PROFILE_SUCCESS,
  payload,
});

export const updateActiveStatus = createPromiseAction(UPDATE_ACTIVE_STATUS);

export const updateActiveStatusSuccess = (payload) => ({
  type: UPDATE_ACTIVE_STATUS_SUCCESS,
  payload,
});

export const updateDeployedAmount = createPromiseAction(UPDATE_DEPLOYED_AMOUNT);

export const updateDeployedAmountSuccess = (payload) => ({
  type: UPDATE_DEPLOYED_AMOUNT_SUCCESS,
  payload,
});

export const getProSummary = createPromiseAction(GET_PRO_SUMMARY);

export const getProSummarySuccess = (payload) => ({
  type: GET_PRO_SUMMARY_SUCCESS,
  payload,
});

export const getProUserPositions = createPromiseAction(GET_PRO_USER_POSITIONS);

export const getProUserPositionsSuccess = (payload) => ({
  type: GET_PRO_USER_POSITIONS_SUCCESS,
  payload,
});

export const updateLeverageMargin = createPromiseAction(UPDATE_LEVERAGE_MARGIN);

export const updateLeverageMarginSuccess = (payload) => ({
  type: UPDATE_LEVERAGE_MARGIN_SUCCESS,
  payload,
});

export const fetchProUsersList = createPromiseAction(FETCH_PRO_USERS_LIST);

export const fetchProUsersListSuccess = (payload) => ({
  type: FETCH_PRO_USERS_LIST_SUCCESS,
  payload,
});

export const getAumHistoryData = createPromiseAction(GET_AUM_HISTORY_DATA);

export const getAumHistoryDataSuccess = (payload) => ({
  type: GET_AUM_HISTORY_DATA_SUCCESS,
  payload,
});

export const getConnectedKeys = createPromiseAction(GET_CONNECTED_KEYS);

export const getConnectedKeysSuccess = (payload) => ({
  type: GET_CONNECTED_KEYS_SUCCESS,
  payload,
});

export const getProStrategyAvailableBalance = createPromiseAction(GET_AVAILABLE_BALANCE_FOR_STRATEGY);

export const getProStrategyAvailableBalanceSuccess = (payload) => ({
  type: GET_AVAILABLE_BALANCE_FOR_STRATEGY_SUCCESS,
  payload,
});

export const getStrategiesTotalReturnsData = createPromiseAction(GET_STRATEGIES_TOTAL_RETURNS);

export const getStrategiesTotalReturnsDataSuccess = (payload) => ({
  type: GET_STRATEGIES_TOTAL_RETURNS_SUCCESS,
  payload,
});

export const fetchSipStrategyPortfolio = createPromiseAction(FETCH_SIP_STRATEGY_PORTFOLIO);

export const fetchSipStrategyPortfolioSuccess = (payload) => ({
  type: FETCH_SIP_STRATEGY_PORTFOLIO_SUCCESS,
  payload,
});

export const fetchHmmSummary = createPromiseAction(FETCH_HMM_SUMMARY);

export const fetchHmmSummarySuccess = (payload) => ({
  type: FETCH_HMM_SUMMARY_SUCCESS,
  payload,
});

export const fetchMonthlyReports = createPromiseAction(FETCH_MONTHLY_REPORTS);

export const fetchMonthlyReportsSuccess = (payload) => ({
  type: FETCH_MONTHLY_REPORTS_SUCCESS,
  payload,
});

export const fetchCreditsCalculation = createPromiseAction(FETCH_CREDITS_CALCULATION);

export const fetchCreditsCalculationSuccess = (payload) => ({
  type: FETCH_CREDITS_CALCULATION_SUCCESS,
  payload,
});

export const exchangeRefresh = createPromiseAction(EXCHANGE_REFRESH);

export const exchangeRefreshSuccess = (payload) => ({
  type: EXCHANGE_REFRESH_SUCCESS,
  payload,
});

export const downloadDepositTransferHistory = createPromiseAction(DOWNLOAD_DEPOSIT_HISTORY);

export const downloadDepositTransferHistorySuccess = (payload) => ({
  type: DOWNLOAD_DEPOSIT_HISTORY_SUCCESS,
  payload,
});

export const resetMonthlyReportDetails = (payload) => ({
  type: RESET_MONTHLY_REPORT_DETAILS,
  payload,
});

export const fetchMonthlyReportDetails = createPromiseAction(FETCH_MONTHLY_REPORT_DETAILS);

export const fetchMonthlyReportDetailsSuccess = (payload) => ({
  type: FETCH_MONTHLY_REPORT_DETAILS_SUCCESS,
  payload,
});

export const redeemSmartCash = createPromiseAction(REDEEM_SMART_CASH);

export const redeemSmartCashSuccess = (payload) => ({
  type: REDEEM_SMART_CASH_SUCCESS,
  payload,
});
export const investSmartCash = createPromiseAction(INVEST_SMART_CASH);

export const investSmartCashSuccess = (payload) => ({
  type: INVEST_SMART_CASH_SUCCESS,
  payload,
});


import { takeLatest, put, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';

import api, { authIP, rootPath } from '../../../api';
import {
  getOpenOrder,
  getOpenOrderSuccess,
  getUsersSybols,
  getUsersSybolsSuccess,
  createOrder,
  createOrderSuccess,
  deleteOrder,
  deleteAllOrders,
  getUsersBalance,
  getUsersBalanceSuccess,
  changeLeverageMargin,
  getFuturesPositions,
  getFuturesPositionsSuccess,
} from './action';

import { DELETE_ORDER } from './types';
import { urls } from './urls';
import store from '../../../store';

function* getOpenOrderReq(action) {
  const { exchangeCode, userId, symbol, accountType } = action.payload;
  const url = urls.getOpenOrder({ exchangeCode, userId, symbol, accountType });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getOpenOrderSuccess(response.data.data));
    }
  } catch (e) {}
}

function* getFuturesPositionsReq(action) {
  const { exchangeCode, userId, symbol } = action.payload;
  const url = urls.getFuturesPositions({ exchangeCode, userId, symbol });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getFuturesPositionsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* getUsersSybolsReq(action) {
  const { accountType } = action.payload;
  const url = urls.getUsersSybols({ accountType });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getUsersSybolsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* getUsersBalanceReq(action) {
  const { exchangeCode, accountType, userId, symbol } = action.payload;
  const url = urls.getUsersBalance({ exchangeCode, accountType, userId, symbol });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getUsersBalanceSuccess(response.data.data));
    }
  } catch (e) {}
}

function* createOrderReq(action) {
  const { exchangeCode, userId, accountType, leverage, margin } = action.payload;

  const url = urls.createOrder({ exchangeCode, userId, accountType, leverage, margin });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.post(url, action.payload));
  });
}

function* changeLeverageMarginReq(action) {
  const { code, userId, marginType, leverage, symbols, reduceOnly } = action.payload;
  const url = urls.changeLeverageMargin({ code, userId, marginType, leverage, symbols, reduceOnly });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url, action.payload));
  });
}

function* deleteOrderReq(action) {
  const { exchangeCode, userId, symbol, orderId, accountType } = action.payload;
  const url = urls.deleteOrder({
    exchangeCode: exchangeCode,
    userId: userId,
    symbol: symbol,
    orderId: orderId,
    accountType: accountType,
  });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.delete(url, action.payload));
  });
}
function* deleteAllOrdersReq(action) {
  const { exchangeCode, userId, symbol, accountType } = action.payload;
  const url = urls.deleteAllOrders({
    exchangeCode: exchangeCode,
    userId: userId,
    symbol: symbol,
    accountType: accountType,
  });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.delete(url, action.payload));
  });
}

export function* orderManagementSaga() {
  yield takeLatest(getOpenOrder, getOpenOrderReq);
  yield takeLatest(getFuturesPositions, getFuturesPositionsReq);
  yield takeLatest(getUsersSybols, getUsersSybolsReq);
  yield takeLatest(createOrder, createOrderReq);
  yield takeLatest(deleteOrder, deleteOrderReq);
  yield takeLatest(deleteAllOrders, deleteAllOrdersReq);
  yield takeLatest(getUsersBalance, getUsersBalanceReq);
  yield takeLatest(changeLeverageMargin, changeLeverageMarginReq);
}

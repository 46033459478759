import { createPromiseAction } from '@adobe/redux-saga-promise';

import { GET_ALL_STRATEGIES, GET_ALL_STRATEGIES_SUCCESS, SET_STRATEGIES_STATUS } from './types';

export const getAllStrategies = (payload) => ({
  type: GET_ALL_STRATEGIES,
  payload,
});

export const getAllStrategiesSuccess = (payload) => ({
  type: GET_ALL_STRATEGIES_SUCCESS,
  payload,
});

export const setStrategiesStatus = createPromiseAction(SET_STRATEGIES_STATUS);

import { SHOW_TOASTER, RESET_TOASTER } from './constants';

const initialState = {
  show: false,
  refresh: true,
};

const toaster = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOASTER:
      return {
        show: true,
        refresh: action.data.refresh || true,
        ...action.data,
      };
    case RESET_TOASTER:
      return {
        msg: '',
        timeout: 0,
        show: false,
        refresh: state.refresh,
      };
    default:
      return state;
  }
};

export default toaster;

import { GET_ALL_STRATEGIES, GET_ALL_STRATEGIES_SUCCESS } from './types';

export const strategiesReducer = (
  state = {
    strategyData: {},
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case GET_ALL_STRATEGIES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_ALL_STRATEGIES_SUCCESS: {
      return {
        ...state,
        strategyData: action.payload,
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

import { all } from 'redux-saga/effects';

import { loginSaga } from './pages/login/store/saga';
import { allCardsSaga } from './pages/home/store/saga';
import { allStoriesSaga } from './pages/stories/store/saga';
import { allStrategiesSaga } from './pages/strategies/store/saga';
import { proSaga } from './pages/sahicoin-pro/store/saga';
import { traderDashboardSaga } from './pages/traderdashboard/store/saga';
import { orderManagementSaga } from './pages/ordermanagement/store/saga';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    allCardsSaga(),
    allStoriesSaga(),
    allStrategiesSaga(),
    proSaga(),
    traderDashboardSaga(),
    orderManagementSaga(),
  ]);
}

import { createPromiseAction } from '@adobe/redux-saga-promise';

import {
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_FUTURES_ORDER,
  CREATE_FUTURES_ORDER_SUCCESS,
  GET_OPEN_ORDERS,
  GET_OPEN_ORDERS_SUCCESS,
  GET_USERS_SYMBOLS,
  GET_USERS_SYMBOLS_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ALL_ORDERS,
  DELETE_ALL_ORDERS_SUCCESS,
  GET_USERS_BALANCE,
  GET_USERS_BALANCE_SUCCESS,
  CHANGE_LEVERAGE_MARGIN,
  CHANGE_LEVERAGE_MARGIN_SUCCESS,
  GET_FUTURES_POSITIONS,
  GET_FUTURES_POSITIONS_SUCCESS,
} from './types';

export const createOrder = createPromiseAction(CREATE_ORDER);

export const createOrderSuccess = (payload) => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

export const getOpenOrder = createPromiseAction(GET_OPEN_ORDERS);

export const getOpenOrderSuccess = (payload) => ({
  type: GET_OPEN_ORDERS_SUCCESS,
  payload,
});

export const getUsersSybols = createPromiseAction(GET_USERS_SYMBOLS);

export const getUsersSybolsSuccess = (payload) => ({
  type: GET_USERS_SYMBOLS_SUCCESS,
  payload,
});

export const getFuturesPositions = createPromiseAction(GET_FUTURES_POSITIONS);

export const getFuturesPositionsSuccess = (payload) => ({
  type: GET_FUTURES_POSITIONS_SUCCESS,
  payload,
});

export const getUsersBalance = createPromiseAction(GET_USERS_BALANCE);

export const getUsersBalanceSuccess = (payload) => ({
  type: GET_USERS_BALANCE_SUCCESS,
  payload,
});

export const deleteOrder = createPromiseAction(DELETE_ORDER);

export const deleteOrderSuccess = (payload) => ({
  type: DELETE_ORDER_SUCCESS,
  payload,
});

export const deleteAllOrders = createPromiseAction(DELETE_ALL_ORDERS);

export const deleteAllOrdersSuccess = (payload) => ({
  type: DELETE_ALL_ORDERS_SUCCESS,
  payload,
});

export const changeLeverageMargin = createPromiseAction(CHANGE_LEVERAGE_MARGIN);

export const changeLeverageMarginSuccess = (payload) => ({
  type: CHANGE_LEVERAGE_MARGIN_SUCCESS,
  payload,
});

import React from "react";
import styled from "styled-components";
import {Link} from 'react-router-dom';

import Button from "./Button";
import Flex from "./Flex";

const ScContainer = styled.div`
  margin: 0 auto;
  max-width: 100%;
  width: 1240px;
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

const ScWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  text-align: center;
  & h2 {
    color: ${({ theme }) => theme.colors.$text};
    font-size: 15rem;
    line-height: 20rem;
    position: relative;
    padding: 0 2rem 0rem;
    &:after {
      content: "";
      width: 100%;
      height: 2.5rem;
      background: ${({ theme }) => theme.colors.$text};
      opacity: 0.1;
      border-radius: 100%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  & h3 {
    color: ${({ theme }) => theme.colors.$text};
    font-size: 2.2rem;
    line-height: 3.4rem;
    font-weight: 700;
    margin: 3rem auto 0.8rem;
  }
  & p {
    color: ${({ theme }) => theme.colors.$text};
    font-size: 1.6rem;
    line-height: 2rem;
  }
  & .goHome_btn {
    margin-top: 5rem;
  }

  @media (max-width: 767px) {
    & h2 {
      font-size: 12rem;
      line-height: 16rem;
    }
    & .goHome_btn {
      margin-top: 8rem;
      width: 100%;
      & button {
        width: 100%;
      }
    }
  }
`;

const NotFound = () => {
  return (
    <ScContainer>
      
      <ScWrapper>
        <Flex
          minHeight="calc(100vh - 4rem)"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          alignItem="center"
        >
          <h2>404</h2>

          <h3>Page not found</h3>
          <p>We&apos;re unable to find the page you&apos;re looking for</p>
          <Link to="/" className="goHome_btn">
              <Button
                type="button"
                name="Go to Homepage"
                onButtonClick={() => {}}
              />
          </Link>
        </Flex>
      </ScWrapper>
    </ScContainer>
  );
}


export default NotFound;

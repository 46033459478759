import {
  GET_OPEN_ORDERS_SUCCESS,
  GET_FUTURES_OPEN_ORDERS_SUCCESS,
  GET_USERS_SYMBOLS_SUCCESS,
  GET_USERS_BALANCE_SUCCESS,
  GET_FUTURES_POSITIONS_SUCCESS,
} from './types';

export const orderManagementReducer = (
  state = {
    openOrders: null,
    openOrdersLoaded: false,
    futuresOpenOrders: null,
    futuresOpenOrdersLoaded: false,
    usersAndSymbols: null,
    usersAndSymbolsLoaded: false,
    usersBalance: null,
    usersBalanceLoaded: false,
    futuresPositions: null,
    futuresPositionsLoaded: false,
  },
  action,
) => {
  switch (action.type) {
    case GET_OPEN_ORDERS_SUCCESS: {
      return {
        ...state,
        openOrders: action.payload,
        openOrdersLoaded: true,
      };
    }
    case GET_FUTURES_POSITIONS_SUCCESS: {
      return {
        ...state,
        futuresPositions: action.payload,
        futuresPositionsLoaded: true,
      };
    }
    case GET_USERS_SYMBOLS_SUCCESS: {
      return {
        ...state,
        usersAndSymbols: action.payload,
        usersAndSymbolsLoaded: true,
      };
    }
    case GET_USERS_BALANCE_SUCCESS: {
      return {
        ...state,
        usersBalance: action.payload,
        usersBalanceLoaded: true,
      };
    }

    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const FlexContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : 0)};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  justify-content: ${(props) => props.justifyContent};
  text-align: ${(props) => props.textAlign};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  overflow ${(props) => (props.overflow ? props.overflow : 'unset')};
  bottom: ${(props) => props.bottom};
  align-content: ${(props) => props.alignContent};
  align-items: ${(props) => props.alignItem};
  flex-direction: ${(props) => props.flexDirection};
  background: ${(props) => (props.background ? props.background : `unset`)};
  flex-wrap: ${(props) => props.flexWrap};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : `unset`)};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : `unset`)};
  min-height: ${(props) => (props.minHeight ? `${props.minHeight}` : `unset`)};
  border-right: ${(props) => (props.borderRight ? props.borderRight : `unset`)};
  border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : `unset`)};
  border-top: ${(props) => (props.borderTop ? props.borderTop : `unset`)};
  cursor: ${(props) => (props.cursor ? props.cursor : `unset`)};
  transform: ${(props) => (props.transform ? props.transform : 'unset')};
  flex: ${(props) => props.flexWidth};
  word-break: ${(props) => (props.wordBreak ? props.wordBreak : 'unset')};
  z-index: ${(props) => (props.zIndex ? props.zIndex : `unset`)};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : `unset`)};
  transition: ${(props) => (props.transition ? props.transition : 'initial')};
  box-shadow: ${(props) => props.boxShadow};
  gap:${(props) => props.gap};
`;

const Flex = (props) => {
  const { children, ...rest } = props;
  return (
    <FlexContainer {...props} {...rest}>
      {children}
    </FlexContainer>
  );
};

Flex.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Flex;

const theme = {
  colors: {
    $theme: '#1554F0',
    $themelight: '#6D8DF1',
    $black: '#333333',
    $white: '#ffffff',
    $gray: '#cccccc',
    $text: '#47526A',
    $background: '#f2f2f2',
    $lightBg: '#F5F7FB',
    $success: '#32CD32',
    $ready: '#FF7A00',
    $danger: '#FF0000',
  },
};

export default theme;

import { LOGIN_FAILURE, LOGIN_SUCCESS } from './types';

const initialState = {
  authenticated: null,
  role: '',
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action?.payload,
        role: action?.payload?.permissions && action?.payload?.permissions?.length ? 'admin' : 'user',
        authenticated: true,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        authenticated: false,
        error: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default login;

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import history from './history';

import { ThemeProvider } from 'styled-components';
import theme from './theme';

import { Provider } from 'react-redux';
import store from '../src/store';

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
        <ThemeProvider theme={theme}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </ThemeProvider>
        </Router>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

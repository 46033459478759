import { CHANGE_PASSWORD, LOGIN, LOGIN_FAILURE, LOGIN_SUCCESS, SEND_OTP } from './types';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});

export const login = createPromiseAction(LOGIN);

export const getOtp = createPromiseAction(SEND_OTP);

export const changePassword = createPromiseAction(CHANGE_PASSWORD);

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { withTheme } from 'styled-components';

export const RouteWithSubRoutes = withTheme((route) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      path={route.path}
      render={(props) =>
        route.isLogin && isAuthenticated ? (
          <Redirect to="/" />
        ) : isAuthenticated || !route.isPrivate ? (
          <route.component {...props} {...route} isAuthenticated={isAuthenticated} theme={route.theme} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
});

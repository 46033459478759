import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resetToaster } from './action';
// import Icon from '../Icons';

import { Container } from './styles';

const Toaster = () => {
  const dispatch = useDispatch();
  const [, setToaster] = useState(false);

  const showToaster = useSelector((state) => state.toaster.show);
  const toasterMsg = useSelector((state) => state.toaster.msg);
  const toasterTime = useSelector((state) => state.toaster.timeout);
  const toasterRefresh = useSelector((state) => state.toaster.refresh);
  const toasterSuccess = useSelector((state) => state.toaster.success);

  const dispatchToStore = useCallback(() => dispatch(resetToaster()), [dispatch]);

  useEffect(() => {
    if (showToaster) {
      const timer = toasterTime || 5000;
      setTimeout(() => {
        dispatchToStore();
        if (toasterRefresh) {
          window.location.reload();
        }
      }, timer);
    }
    setToaster(showToaster);
    // TODO: handle dependencies toasterRefresh, toasterTime
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showToaster, dispatchToStore]);

  return (
    <Container>
      {showToaster ? (
        <div className={`flex falign-c toaster ${toasterSuccess ? 'success' : 'error'}`}>
          <span className="toaster-icon mr16">{/* <Icon name={toasterSuccess ? 'success' : 'error'} /> */}</span>
          <p>{toasterMsg || 'Successful'}</p>
        </div>
      ) : null}
    </Container>
  );
};

export default Toaster;

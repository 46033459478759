import { takeLatest, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';

import { authIP } from '../../../api';
import { urls } from './urls';
import { changePassword, getOtp, login } from './action';
import { getLocalStorage } from '../../../utils/helper';

function* loginRequest(action) {
  let params = {
    audience: 'https://sahicoin.com',
  };
  const apiNew = axios.create({
    baseURL: authIP,
    withCredentials: false,
  });
  const { type, otp, phone_number, username, password } = action.payload;
  const url = urls.login();
  if (type === 'otp') {
    params = {
      password: otp,
      username: phone_number,
      grant_type: 'http://sahicoin.com/oauth/grant-type/passwordless/otp',
    };
  } else {
    params = {
      username,
      password,
      grant_type: 'password',
    };
  }
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => apiNew.post(url, params));
  });
}

function* getOtpRequest(action) {
  const { phone } = action.payload;
  const url = urls.getOtp();
  const params = {
    action: 'LOGIN',
    phoneNumber: phone,
  };
  const apiNew = axios.create({
    baseURL: authIP,
    withCredentials: false,
  });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => apiNew.post(url, params));
  });
}

function* changePasswordReq(action) {
  const url = urls.changePassword();
  const token = getLocalStorage('token');
  const apiNew = axios.create({
    baseURL: authIP,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => apiNew.post(url, action.payload));
  });
}

export function* loginSaga() {
  yield takeLatest(login, loginRequest);
  yield takeLatest(getOtp, getOtpRequest);
  yield takeLatest(changePassword, changePasswordReq);
}

export const CREATE_ORDER = 'ordermanagement/CREATE_ORDER';
export const CREATE_ORDER_SUCCESS = 'ordermanagement/CREATE_ORDER_SUCCESS';

export const CREATE_FUTUTES_ORDER = 'ordermanagement/CREATE_ORDER';
export const CREATE_FUTURES_ORDER_SUCCESS = 'ordermanagement/CREATE_ORDER_SUCCESS';

export const GET_OPEN_ORDERS = 'ordermanagement/GET_OPEN_ORDERS';
export const GET_OPEN_ORDERS_SUCCESS = 'ordermanagement/GET_OPEN_ORDERS_SUCCESS';

export const GET_FUTURES_OPEN_ORDERS = 'ordermanagement/GET_FUTURES_OPEN_ORDERS';
export const GET_FUTURES_OPEN_ORDERS_SUCCESS = 'ordermanagement/GET_FUTURES_OPEN_ORDERS_SUCCESS';

export const GET_USERS_SYMBOLS = 'ordermanagement/GET_USERS_SYMBOLS';
export const GET_USERS_SYMBOLS_SUCCESS = 'ordermanagement/GET_USERS_SYMBOLS_SUCCESS';

export const GET_USERS_BALANCE = 'ordermanagement/GET_USERS_BALANCE';
export const GET_USERS_BALANCE_SUCCESS = 'ordermanagement/GET_USERS_BALANCE_SUCCESS';

export const GET_FUTURES_POSITIONS = 'ordermanagement/GET_FUTURES_POSITIONS';
export const GET_FUTURES_POSITIONS_SUCCESS = 'ordermanagement/GET_FUTURES_POSITIONS_SUCCESS';

export const DELETE_ORDER = 'ordermanagement/DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'ordermanagement/DELETE_ORDER_SUCCESS';

export const DELETE_ALL_ORDERS = 'ordermanagement/DELETE_ALL_ORDERS';
export const DELETE_ALL_ORDERS_SUCCESS = 'ordermanagement/DELETE_ALL_ORDERS_SUCCESS';

export const CHANGE_LEVERAGE_MARGIN = 'ordermanagement/CHANGE_LEVERAGE_MARGIN';
export const CHANGE_LEVERAGE_MARGIN_SUCCESS = 'ordermanagement/CHANGE_LEVERAGE_MARGIN_SUCCESS';

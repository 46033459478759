import { takeLatest, put, call } from 'redux-saga/effects';

import api from '../../../api';

import { implementPromiseAction } from '@adobe/redux-saga-promise';

import {
  fetchAllStoriesSuccess,
  updateStoryStatusSuccess,
  createMktPage,
  storiesReset,
  fetchAllStories,
} from './action';

import { EDIT_STORY, FETCH_ALL_STORIES, UPDATE_STORY_STATUS } from './types';

import { showToaster } from '../../../components/shared/Toaster/action';

import { urls } from './urls';

function* getAllStories(action) {
  const { isAdmin, params } = action.payload;
  const url = urls.fetchAllStories(isAdmin, params);
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchAllStoriesSuccess(response.data.data));
    }
  } catch (e) {}
}

function* updateStoryStatus(action) {
  const { isAdmin, slug, published } = action.payload;
  const url = urls.updateStoryStatus(isAdmin, slug, published);
  try {
    const response = yield api.put(url);
    if (response?.data?.data) {
      yield put(
        showToaster({ msg: `Story status updated successfully`, timeout: 2000, success: true, refresh: false }),
      );
      yield put(updateStoryStatusSuccess(action.payload));
    }
  } catch (e) {
    yield put(showToaster({ msg: `Story updation failed`, timeout: 2000, success: false, refresh: false }));
  }
}

function* editStory(action) {
  const { isAdmin, slug } = action.payload;
  const url = urls.editStory(isAdmin, slug);
  try {
    const response = yield api.put(url, action.payload.data);
    if (response?.data?.data) {
      yield put(showToaster({ msg: `Story updated successfully`, timeout: 2000, success: true, refresh: false }));
      yield put(storiesReset());
      yield put(fetchAllStories([`page=0`]));
    }
  } catch (e) {
    yield put(showToaster({ msg: `Story updation failed`, timeout: 2000, success: false, refresh: false }));
  }
}

function* createMktPageReq(action) {
  const { isAdmin, ...rest } = action.payload;
  const url = urls.createMktPage(isAdmin);

  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.post(url, rest));
    });
    yield put(storiesReset());
    yield put(fetchAllStories([`page=0`]));
  } catch (e) {}
}

export function* allStoriesSaga() {
  yield takeLatest(FETCH_ALL_STORIES, getAllStories);
  yield takeLatest(UPDATE_STORY_STATUS, updateStoryStatus);
  yield takeLatest(EDIT_STORY, editStory);
  yield takeLatest(createMktPage, createMktPageReq);
}

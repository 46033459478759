import { createPromiseAction } from '@adobe/redux-saga-promise';

import {
  FETCH_TRADER_STRATEGIES,
  FETCH_TRADER_STRATEGIES_SUCCESS,
  FETCH_TRADER_STRATEGY_INFO,
  FETCH_TRADER_STRATEGY_INFO_SUCCESS,
  FETCH_TRADERS_ACTIVE_CALLS,
  FETCH_TRADERS_ACTIVE_CALLS_SUCCESS,
  FETCH_TRADERS_PAST_CALLS,
  FETCH_TRADERS_PAST_CALLS_SUCCESS,
} from './types';

export const fetchTraderStrategies = createPromiseAction(FETCH_TRADER_STRATEGIES);

export const fetchTraderStrategiesSuccess = (payload) => ({
  type: FETCH_TRADER_STRATEGIES_SUCCESS,
  payload,
});

export const fetchTraderStrategyInfo = createPromiseAction(FETCH_TRADER_STRATEGY_INFO);

export const fetchTraderStrategyInfoSuccess = (payload) => ({
  type: FETCH_TRADER_STRATEGY_INFO_SUCCESS,
  payload,
});

export const fetchTradersActiveCalls = createPromiseAction(FETCH_TRADERS_ACTIVE_CALLS);

export const fetchTradersActiveCallsSuccess = (payload) => ({
  type: FETCH_TRADERS_ACTIVE_CALLS_SUCCESS,
  payload,
});

export const fetchTradersPastCalls = createPromiseAction(FETCH_TRADERS_PAST_CALLS);

export const fetchTradersPastCallsSuccess = (payload) => ({
  type: FETCH_TRADERS_PAST_CALLS_SUCCESS,
  payload,
});

export const urls = {
  createOrder: ({ exchangeCode, userId, accountType }) =>
    accountType === 'FUTURES'
      ? `/api/v1/order-mgt/${exchangeCode}/futures/order?userId=${userId}`
      : `/api/v1/order-mgt/${exchangeCode}/order?userId=${userId}`,

  getFuturesPositions: ({ userId, symbol, exchangeCode }) =>
    `/api/v1/order-mgt/${exchangeCode}/futures/position?userId=${userId}&symbol=${symbol}`,

  getOpenOrder: ({ exchangeCode, userId, symbol, accountType }) =>
    accountType === 'FUTURES'
      ? `/api/v1/order-mgt/${exchangeCode}/futures/open-orders?userId=${userId}&symbol=${symbol}`
      : `/api/v1/order-mgt/${exchangeCode}/open-orders?userId=${userId}&symbol=${symbol}`,

  getUsersSybols: ({ accountType }) => `/api/v1/order-mgt/${accountType}`,

  getUsersBalance: ({ exchangeCode, accountType, userId, symbol }) =>
    `/api/v1/order-mgt/${exchangeCode}/${accountType}/balance?userId=${userId}&symbol=${symbol}`,

  deleteOrder: ({ exchangeCode, userId, symbol, orderId, accountType }) =>
    accountType === 'FUTURES'
      ? `/api/v1/order-mgt/${exchangeCode}/futures/order?userId=${userId}&symbol=${symbol}&orderId=${orderId}`
      : `/api/v1/order-mgt/${exchangeCode}/order?userId=${userId}&symbol=${symbol}&orderId=${orderId}`,

  deleteAllOrders: ({ exchangeCode, userId, symbol, accountType }) =>
    accountType === 'FUTURES'
      ? `/api/v1/order-mgt/${exchangeCode}/futures/orders?userId=${userId}&symbol=${symbol}`
      : `/api/v1/order-mgt/${exchangeCode}/orders?userId=${userId}&symbol=${symbol}`,

  changeLeverageMargin: ({ code, userId, marginType, leverage, symbols }) =>
    `/api/v1/admin/exchange/${code}/leverage-margin?userId=${userId}&marginType=${marginType}&leverage=${leverage}&symbols=${symbols}`,
};

import { takeLatest, put, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';

import api, { authIP, rootPath } from '../../../api';
import {
  fetchTraderStrategies,
  fetchTraderStrategiesSuccess,
  fetchTraderStrategyInfo,
  fetchTraderStrategyInfoSuccess,
  fetchTradersActiveCalls,
  fetchTradersActiveCallsSuccess,
  fetchTradersPastCalls,
  fetchTradersPastCallsSuccess,
} from './action';

import {} from './types';
import { urls } from './urls';
import store from '../../../store';

function* fetchTraderStrategiesReq(action) {
  const { userId } = action.payload;
  const url = urls.fetchTraderStrategies({ userId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchTraderStrategiesSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchTraderStrategyInfoReq(action) {
  const { userId, strategyId } = action.payload;
  const url = urls.fetchTraderStrategyInfo({ userId, strategyId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchTraderStrategyInfoSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchTradersActiveCallsReq(action) {
  const { userId, strategyId } = action.payload;
  const url = urls.fetchTradersActiveCalls({ userId, strategyId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchTradersActiveCallsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchTradersPastCallsReq(action) {
  const { userId, strategyId, page, size, fromTime, toTime } = action.payload;
  const url = urls.fetchTradersPastCalls({ userId, strategyId, page, size, fromTime, toTime });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchTradersPastCallsSuccess(response.data.data));
    }
  } catch (e) {}
}

// function* updateLeverageMarginReq(action) {
//   const { strategyId, userId, code, symbols, leverage, marginType } = action.payload;

//   const url = urls.updateLeverageMargin({ strategyId, userId, code, symbols, leverage, marginType });
//   yield call(implementPromiseAction, action, function* () {
//     return yield call(() => api.put(url, action.payload));
//   });
// }

export function* traderDashboardSaga() {
  yield takeLatest(fetchTraderStrategies, fetchTraderStrategiesReq);
  yield takeLatest(fetchTraderStrategyInfo, fetchTraderStrategyInfoReq);
  yield takeLatest(fetchTradersActiveCalls, fetchTradersActiveCallsReq);
  yield takeLatest(fetchTradersPastCalls, fetchTradersPastCallsReq);
}

import { combineReducers } from 'redux';
import { cardsReducer } from '../pages/home/store/reducer';
import toaster from '../components/shared/Toaster/reducer';
import login from '../pages/login/store/reducer';
import { storiesReducer } from '../pages/stories/store/reducer';
import { strategiesReducer } from '../pages/strategies/store/reducer';
import { proReducer } from '../pages/sahicoin-pro/store/reducer';
import { traderDashboardReducer } from '../pages/traderdashboard/store/reducer';
import { orderManagementReducer } from '../pages/ordermanagement/store/reducer';

const rootReducer = combineReducers({
  cardsReducer,
  toaster,
  login,
  storiesReducer,
  strategiesReducer,
  proReducer,
  traderDashboardReducer,
  orderManagementReducer,
});

export default rootReducer;

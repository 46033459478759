export const urls = {
  fetchAllStories: (isAdmin, param) => {
    if (isAdmin) {
      return `/api/v1/mkt-page${param && param.length ? `?${param.join('&')}` : ''}`;
    } else {
      return `/api/v1/mkt-page/user${param && param.length ? `?${param.join('&')}` : ''}`;
    }
  },
  updateStoryStatus: (isAdmin, slug, status) => {
    if (isAdmin) {
      return `api/v1/mkt-page/${slug}/${status}`;
    } else {
      return `api/v1/mkt-page/user/${slug}/${status}`;
    }
  },
  createMktPage: (isAdmin) => {
    if (isAdmin) {
      return `/api/v1/mkt-page`;
    } else {
      return `/api/v1/mkt-page/user`;
    }
  },
  editStory: (isAdmin, slug) => {
    if (isAdmin) {
      return `/api/v1/mkt-page/${slug}`;
    } else {
      return `/api/v1/mkt-page/user/${slug}`;
    }
  },
};

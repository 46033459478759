import { takeLatest, put, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';

import api, { authIP, rootPath } from '../../../api';
import { getAllStrategiesSuccess, setStrategiesStatus } from './action';

import { GET_ALL_STRATEGIES } from './types';
import { urls } from './urls';
import store from '../../../store';

function* getAllStrategies(action) {
  const state = store.getState();
  const url = urls.getAllStrategies(action.payload);
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getAllStrategiesSuccess(response.data.data));
    }
  } catch (e) {}
}

function* setStrategiesStatusReq(action) {
  const { strategyKey, value } = action.payload;
  const url = urls.setStrategiesStatus({ strategyKey, value });
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.post(url, { strategyKey, value }));
    });
  } catch (e) {}
}

export function* allStrategiesSaga() {
  yield takeLatest(GET_ALL_STRATEGIES, getAllStrategies);
  yield takeLatest(setStrategiesStatus, setStrategiesStatusReq);
}

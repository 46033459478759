
import {
  FETCH_ALL_STORIES,
  FETCH_ALL_STORIES_SUCCESS,
  FETCH_ALL_STORIES_FAILURE,
  STORIES_RESET,
  UPDATE_STORY_STATUS_SUCCESS
} from './types';

export const storiesReducer = (
  state = {
    stories: {
      content: [],
    },
    isFetching: false,
    isFetched: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_ALL_STORIES: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    }
    case FETCH_ALL_STORIES_SUCCESS: {
      const list = [...state.stories.content, ...action.data.content];
      return {
        ...state,
        stories: { ...action.data, content: list },
        isFetching: false,
        isFetched: true,
      };
    }
    case FETCH_ALL_STORIES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
      };
    }
    case STORIES_RESET: {
      return {
        ...state,
        stories: {
          content: [],
        },
        isFetching: false,
        isFetched: true,
      };
    }

    case UPDATE_STORY_STATUS_SUCCESS: {
      let data = state.stories.content;
      let indexData = data.find(data => data.slug === action.data.slug);
      let index = data.findIndex(data => data.slug === action.data.slug);
      indexData = {
        ...indexData,
        published: !indexData.published
      }
      data[index] = indexData;
      return {
        ...state,
        stories: {
          ...state.stories,
          content: data
        }
      }
    }

    default:
      return state;
  }
};

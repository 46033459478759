import {
  ALLCARDS,
  ALLCARDS_FAILURE,
  ALLCARDS_SUCCESS,
  ALLCARDS_RESET,
  CREATE_CARD,
  CREATE_CARD_SUCCESS,
  SEND_IN_REVIEW,
  SEND_IN_REVIEW_SUCCESS,
  PUBLISH_CARD,
  PUBLISH_CARD_SUCCESS,
  FETCH_SUGGESTIONS_SUCCESS,
  FETCH_TOPICS_SUCCESS,
  FETCH_AUTHOR_PROFILE_SUCCESS,
  REJECT_CARD,
  REJECT_CARD_SUCCESS,
  FETCH_CURRENT_USER_PROFILE_SUCCESS,
  DELETE_CARD_FAILURE,
  DELETE_CARD_SUCCESS,
  DELETE_CARD,
  FETCH_TAG_LIST_SUCCESS,
  EDIT_CARD_SUCCESS,
} from './types';

const formatSuggestions = (list) => {
  return list.map((item) => {
    return {
      id: item?.userId,
      display: item?.userName,
    };
  });
};

const formatTopics = (topics) => {
  if (!topics?.length) {
    return [];
  }
  return topics.map((topic) => {
    return { label: topic.firstName, value: topic.userId };
  });
};

export const cardsReducer = (
  state = {
    cards: {
      content: [],
    },
    isFetching: false,
    isFetched: null,
    cardSubmit: null,
    suggestions: [],
    originalSuggestions: [],
    topics: [],
    authorProfileData: {},
    currentUserProfile: null,
    currentUserProfileLoaded: false,
  },
  action,
) => {
  switch (action.type) {
    case ALLCARDS: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
      };
    }
    case ALLCARDS_SUCCESS: {
      const list = [...state.cards.content, ...action.payload.content];
      return {
        ...state,
        cards: { ...action.payload, content: list },
        isFetching: false,
        isFetched: true,
      };
    }
    case ALLCARDS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
      };
    }
    case ALLCARDS_RESET: {
      return {
        ...state,
        cards: {
          content: [],
        },
      };
    }
    case CREATE_CARD: {
      return {
        ...state,
        cardSubmit: null,
      };
    }
    case CREATE_CARD_SUCCESS: {
      const { id, result } = action.payload;
      if (id) {
        return {
          ...state,
          cardSubmit: result,
          id,
        };
      } else {
        return {
          ...state,
          cardSubmit: result,
        };
      }
    }
    case EDIT_CARD_SUCCESS: {
      const data = action.payload;
      try {
        const cardToBeEdited = state?.cards?.content?.findIndex((card) => card?.id === data?.id);
        let cards = state?.cards?.content;
        cards?.splice(cardToBeEdited, 1, data);
        return {
          ...state,
          cards: {
            ...state.cards,
            content: cards,
          },
          cardSubmit: 'close',
        };
      } catch (e) {
        return {
          ...state,
        };
      }
    }
    case REJECT_CARD: {
      return {
        ...state,
        cardSubmit: null,
      };
    }
    case REJECT_CARD_SUCCESS: {
      const cardId = action.payload?.id;
      try {
        const cardToBeEdited = state?.cards?.content?.findIndex((card) => card.id === cardId);
        let cards = state?.cards?.content;
        const card = cards[cardToBeEdited];
        card['state'] = 'REJECTED';
        cards?.splice(cardToBeEdited, 1, card);
        return {
          ...state,
          cards: {
            ...state.cards,
            content: cards,
          },
        };
      } catch (e) {
        return {
          ...state,
          cardSubmit: action.payload?.result,
        };
      }
    }
    case SEND_IN_REVIEW: {
      return {
        ...state,
        cardSubmit: null,
      };
    }
    case SEND_IN_REVIEW_SUCCESS: {
      const cardId = action.payload?.id;
      try {
        const cardToBeEdited = state?.cards?.content?.findIndex((card) => card.id === cardId);
        let cards = state?.cards?.content;
        const card = cards[cardToBeEdited];
        card['state'] = 'IN_REVIEW';
        cards?.splice(cardToBeEdited, 1, card);
        return {
          ...state,
          cards: {
            ...state.cards,
            content: cards,
          },
        };
      } catch (e) {
        return {
          ...state,
          cardSubmit: action.payload?.result,
        };
      }
    }
    case PUBLISH_CARD: {
      return {
        ...state,
        cardSubmit: null,
      };
    }
    case PUBLISH_CARD_SUCCESS: {
      const cardId = action.payload?.id;
      try {
        const cardToBeEdited = state?.cards?.content?.findIndex((card) => card.id === cardId);
        let cards = state?.cards?.content;
        const card = cards[cardToBeEdited];
        card['state'] = 'PUBLISHED';
        cards?.splice(cardToBeEdited, 1, card);
        return {
          ...state,
          cards: {
            ...state.cards,
            content: cards,
          },
        };
      } catch (e) {
        return {
          ...state,
          cardSubmit: action.payload?.result,
        };
      }
    }
    case FETCH_SUGGESTIONS_SUCCESS: {
      return {
        ...state,
        suggestions: formatSuggestions(action.payload),
        originalSuggestions: action.payload,
      };
    }
    case FETCH_TOPICS_SUCCESS: {
      return {
        ...state,
        topics: formatTopics(action.payload),
      };
    }
    case FETCH_AUTHOR_PROFILE_SUCCESS: {
      return {
        ...state,
        authorProfile: action.payload || {},
      };
    }

    case FETCH_CURRENT_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        currentUserProfile: action.payload || {},
        currentUserProfileLoaded: true,
      };
    }

    case DELETE_CARD: {
      return {
        ...state,
        cardSubmit: null,
      };
    }

    case DELETE_CARD_FAILURE: {
      return {
        ...state,
        cardSubmit: false,
      };
    }

    case DELETE_CARD_SUCCESS: {
      const cardId = action.payload?.id;
      try {
        const cardToBeEdited = state?.cards?.content?.findIndex((card) => card.id === cardId);
        let cards = state?.cards?.content;
        cards?.splice(cardToBeEdited, 1);
        return {
          ...state,
          cardSubmit: action?.payload?.result,
          cards: {
            ...state.cards,
            content: cards,
          },
        };
      } catch (e) {
        return {
          ...state,
          cardSubmit: 'success',
        };
      }
    }

    case FETCH_TAG_LIST_SUCCESS: {
      return {
        ...state,
        tagList: formatTopics(action.payload),
      };
    }

    default:
      return state;
  }
};

import React, { Suspense, useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';

import { routes } from './routes';
import { RouteWithSubRoutes } from './SubRoutes';
import { AuthContext } from '../context/auth';
import Toaster from '../components/shared/Toaster';
import { useDispatch } from 'react-redux';
import { getPermissions } from '../utils/helper';
import { loginSuccess } from '../pages/login/store/action';

const Routes = (props) => {
  const dispatch = useDispatch();
  const existinAuth = Boolean(localStorage.getItem('isAuthenticated'));
  const [isAuthenticated, setIsAuthenticated] = useState(existinAuth);

  const setAuthentication = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const permissions = getPermissions();
    dispatch(loginSuccess({ permissions }));
  });

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated: setAuthentication }}>
        <Switch>
          {routes.map((route, index) => (
            <RouteWithSubRoutes key={index} {...route} />
          ))}
        </Switch>
        <Toaster />
      </AuthContext.Provider>
    </Suspense>
  );
};
export default Routes;

import { createPromiseAction } from '@adobe/redux-saga-promise';

import {
  FETCH_ALL_STORIES, 
  FETCH_ALL_STORIES_FAILURE, 
  FETCH_ALL_STORIES_SUCCESS, 
  STORIES_RESET, 
  UPDATE_STORY_STATUS, 
  UPDATE_STORY_STATUS_FAILURE, 
  UPDATE_STORY_STATUS_SUCCESS,
  EDIT_STORY, 
  EDIT_STORY_FAILURE, 
  EDIT_STORY_SUCCESS,
  CREATE_MKT_PAGE
} from './types';

export const fetchAllStories = (payload) => ({
  type: FETCH_ALL_STORIES,
  payload
});

export const fetchAllStoriesSuccess = (data) => ({
  type: FETCH_ALL_STORIES_SUCCESS,
  data
});

export const fetchAllStoriesFailure = () => ({
  type: FETCH_ALL_STORIES_FAILURE
});

export const storiesReset = () => ({
  type: STORIES_RESET
});

export const updateStoryStatus = (payload) =>({
  type: UPDATE_STORY_STATUS,
  payload
})

export const updateStoryStatusSuccess = (data) =>({
  type: UPDATE_STORY_STATUS_SUCCESS,
  data
})

export const updateStoryStatusFailure = () =>({
  type: UPDATE_STORY_STATUS_FAILURE,
})


export const editStory = (payload) =>({
  type: EDIT_STORY,
  payload
})

export const editStorySuccess = (data) =>({
  type: EDIT_STORY_SUCCESS,
  data
})

export const editStoryFailure = () =>({
  type: EDIT_STORY_FAILURE,
})


export const createMktPage = createPromiseAction(CREATE_MKT_PAGE);

export const FETCH_ALL_STORIES = 'stories/FETCH_ALL_STORIES';
export const FETCH_ALL_STORIES_SUCCESS = 'stories/FETCH_ALL_STORIES_SUCCESS';
export const FETCH_ALL_STORIES_FAILURE = 'stories/FETCH_ALL_STORIES_FAILURE';
export const STORIES_RESET = 'stories/STORIES_RESET';
export const UPDATE_STORY_STATUS = 'stories/UPDATE_STORY_STATUS';
export const UPDATE_STORY_STATUS_SUCCESS = 'stories/UPDATE_STORY_STATUS_SUCCESS';
export const UPDATE_STORY_STATUS_FAILURE = 'stories/UPDATE_STORY_STATUS_FAILURE';
export const CREATE_MKT_PAGE = 'mkt/CREATE_MKT_PAGE';
export const EDIT_STORY = 'stories/EDIT_STORY';
export const EDIT_STORY_SUCCESS = 'stories/EDIT_STORY_SUCCESS';
export const EDIT_STORY_FAILURE = 'stories/EDIT_STORY_FAILURE';

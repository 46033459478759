export const urls = {
  fetchAllCards: (param, role) => {
    if (role === 'user') {
      return `/api/v1/cards${param && param.length ? `?${param.join('&')}` : ''}`;
    }
    return `/api/v1/admin/cards${param && param.length ? `?${param.join('&')}` : ''}`;
  },
  rejectCard: (id) => `/api/v1/admin/card/${id}/reject`,
  sendCardInReview: (id) => `/api/v1/admin/card/${id}/to-review`,
  publishCard: (id, notifyRecipients) => `/api/v1/admin/card/${id}/publish?notifyRecipients=${notifyRecipients}`,
  getImageUrl: (isAdmin) => {
    if (isAdmin) {
      return `/api/v1/admin/card/document`;
    }
    return `/api/v1/card/document`;
  },
  getVideoUrl: (isAdmin) => {
    if (isAdmin) {
      return `/api/v1/admin/doc/upload-url/generate`;
    }
    return `/api/v1/admin/doc/upload-url/generate`;
  },
  createCard: (id, role) => {
    if (role === 'user') {
      return `/api/v1/card${id ? `/${id}` : ''}`;
    }
    return `/api/v1/admin/card${id ? `/${id}` : ''}`;
  },
  fetchSuggestions: (searchText) => `/api/v1/userprofile/search/${searchText}`,
  fetchTopics: () => `/api/v1/userprofile/topics`,
  fetchAuthorsList: (searchText) => `/api/v1/userprofile/search/${searchText}`,
  fetchAuthorProfile: (userId) => `/api/v1/userprofile/${userId}`,
  fetchCurrentUserProfile: () => `/api/v1/userprofile`,
  deleteCard: (id, role) => {
    if (role === 'admin') {
      return `/api/v1/admin/card/${id}`;
    }
    return `/api/v1/card/${id}`;
  },
  applyFilters: (id) => `/api/v1/admin/card/${id}`,
  sendBoostCards: () => `/api/v1/feed/cards/boost`,
  fetchTagList: (search) => `/api/v1/tag/search?name=${search}`,
  createTag: (name) => `/api/v1/admin/tag?name=${name}`,
  addUserToPro: (accountType) => `/api/v1/admin/pro?accountType=${accountType}`,
};

import jwt_decode from 'jwt-decode';

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage[key];
};

export const logout = () => {
  localStorage.clear();
  window.location = '/login';
};

const currencyMap = {
  USD: 'US$ ',
};

export const getCurrency = (currency = 'USD') => {
  return currencyMap[currency];
};

export const getDate = (date) => {
  let parseDate = new Date(date);
  const dateStr = parseDate.toLocaleDateString('en-GB', { year: 'numeric', day: 'numeric', month: 'short' });
  const time = parseDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  return `${dateStr}, ${time}`;
};

// export const getDate = (date, dateOnly) => {
//   let parseDate = new Date(date);
//   const dateStr = parseDate.toLocaleDateString("en-GB", {
//     year: "numeric",
//     day: "numeric",
//     month: "short",
//   });
//   const time = parseDate.toLocaleTimeString("en-US", {
//     hour: "2-digit",
//     minute: "2-digit",
//   });
//   return `${dateStr} ${dateOnly ? "" : `, ${time}`}`;
// };

export const parsePrice = ({ currency, price }) => {
  if (!price) {
    return '';
  }
  const curr = getCurrency(currency);
  return `${curr} ${price}`;
};

export const findInArray = (array, item) => array?.includes(item);

export const getPermissions = (token) => {
  const tokenValue = token || getLocalStorage('token');
  const decodeToken = tokenValue && jwt_decode(tokenValue);
  return decodeToken ? decodeToken?.permissions.join(',') : '';
};

export const isNotEmptyObject = (obj) => {
  if (obj !== null && obj !== undefined) {
    return Object.values(obj).length > 0;
  }
};

export const formatCurrency = (number, roundingOption, removesign) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return 'Invalid input. Please provide a valid number.';
  }

  if (roundingOption !== 'roundup' && roundingOption !== 'rounddown') {
    return 'Invalid rounding option. Please provide "roundup" or "rounddown".';
  }

  const roundedNumber = roundingOption === 'roundup' ? Math.ceil(number * 100) / 100 : Math.floor(number * 100) / 100;
  const formattedNumber = roundedNumber.toFixed(2);

  return removesign
    ? formattedNumber.replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : '$' + formattedNumber.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const getAPY = (returns, start) => {
  let start_date = new Date(start);
  let one_day = 1000 * 60 * 60 * 24;
  let current_date = new Date();
  let no_of_days = Math.ceil((current_date - start_date)/one_day)
  return (Number(returns)/no_of_days * 365).toFixed(2)

}

import {
  FETCH_PRO_USER_PROFILE_SUCCESS,
  PRO_STRATEGIES_SUCCESS,
  PRO_SUBSCRIPTION_DETAILS_SUCCESS,
  PRO_PAST_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_CONNECTED_KEYS_SUCCESS,
  GET_PRO_SUMMARY_SUCCESS,
  GET_PRO_USER_POSITIONS_SUCCESS,
  FETCH_PRO_USERS_LIST_SUCCESS,
  RESET_PRO_SUBSCRIPTION_DETAILS,
  GET_AUM_HISTORY_DATA_SUCCESS,
  GET_STRATEGIES_TOTAL_RETURNS_SUCCESS,
  FETCH_SIP_STRATEGY_PORTFOLIO_SUCCESS,
  FETCH_HMM_SUMMARY_SUCCESS,
  FETCH_MONTHLY_REPORTS_SUCCESS,
  FETCH_CREDITS_CALCULATION_SUCCESS,
  RESET_MONTHLY_REPORT_DETAILS,
  FETCH_MONTHLY_REPORT_DETAILS_SUCCESS,
  GET_AVAILABLE_BALANCE_FOR_STRATEGY_SUCCESS,
} from './types';

export const proReducer = (
  state = {
    connectedKeys: [],
    connectedKeysLoaded: false,
    proStrategies: null,
    proStrategyAvailableBalance: null,
    proStrategyAvailableBalanceLoaded: false,
    proStrategiesLoaded: false,
    proSubscriptionDetails: null,
    proSubscriptionDetailsLoaded: false,
    proUserProfileLoaded: false,
    proUserProfile: {},
    proPastSubscriptionDetailsLoaded: false,
    proSubscriptionDetailsLoaded: null,
    proSummary: null,
    proSummaryLoaded: false,
    proUserPositions: null,
    proUserPositionsLoaded: false,
    proUsersList: null,
    proUsersListLoaded: false,
    getAumHistoryData: null,
    getAumHistoryDataLoaded: false,
    strategiesTotalReturnsData: null,
    strategiesTotalReturnsDataLoaded: false,
    sipStrategyPortfolio: null,
    sipStrategyPortfolioLoaded: false,
    hmmSummary: null,
    hmmSummaryLoaded: false,
    monthlyReports: null,
    monthlyReportsLoaded: false,
    creditsCalculation: null,
    creditsCalculationLoaded: false,
    monthlyReportDetails: null,
    monthlyReportDetailsLoaded: false,
  },
  action,
) => {
  switch (action.type) {
    case RESET_PRO_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        connectedKeys: [],
        connectedKeysLoaded: false,
        proSubscriptionDetails: null,
        proStrategyAvailableBalance: null,
        proStrategyAvailableBalanceLoaded: false,
        proSubscriptionDetailsLoaded: false,
        proUserProfileLoaded: false,
        proUserProfile: {},
        proPastSubscriptionDetailsLoaded: false,
        proSubscriptionDetailsLoaded: null,
        proSummary: null,
        proSummaryLoaded: false,
        proUserPositions: null,
        proUserPositionsLoaded: false,
        getAumHistoryData: null,
        getAumHistoryDataLoaded: false,
        strategiesTotalReturnsData: null,
        strategiesTotalReturnsDataLoaded: false,
        sipStrategyPortfolio: null,
        sipStrategyPortfolioLoaded: false,
        hmmSummary: null,
        hmmSummaryLoaded: false,
        monthlyReports: null,
        monthlyReportsLoaded: false,
        creditsCalculation: null,
        creditsCalculationLoaded: false,
      };
    }
    case PRO_STRATEGIES_SUCCESS: {
      return {
        ...state,
        proStrategies: action.payload,
        proStrategiesLoaded: true,
      };
    }
    case PRO_SUBSCRIPTION_DETAILS_SUCCESS: {
      return {
        ...state,
        proSubscriptionDetails: action.payload,
        proSubscriptionDetailsLoaded: true,
      };
    }

    case PRO_PAST_SUBSCRIPTION_DETAILS_SUCCESS: {
      return {
        ...state,
        proPastSubscriptionDetails: action.payload,
        proPastSubscriptionDetailsLoaded: true,
      };
    }

    case FETCH_PRO_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        proUserProfile: action.payload || {},
        proUserProfileLoaded: true,
      };
    }

    case GET_PRO_SUMMARY_SUCCESS: {
      return {
        ...state,
        proSummary: action.payload,
        proSummaryLoaded: true,
      };
    }
    case GET_PRO_USER_POSITIONS_SUCCESS: {
      return {
        ...state,
        proUserPositions: action.payload,
        proUserPositionsLoaded: true,
      };
    }

    case GET_AUM_HISTORY_DATA_SUCCESS: {
      return {
        ...state,
        getAumHistoryData: action.payload,
        getAumHistoryDataLoaded: true,
      };
    }

    case FETCH_PRO_USERS_LIST_SUCCESS: {
      return {
        ...state,
        proUsersList: action.payload,
        proUsersListLoaded: true,
      };
    }

    case GET_STRATEGIES_TOTAL_RETURNS_SUCCESS: {
      return {
        ...state,
        strategiesTotalReturnsData: action.payload,
        strategiesTotalReturnsDataLoaded: true,
      };
    }

    case FETCH_SIP_STRATEGY_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        sipStrategyPortfolio: action.payload,
        sipStrategyPortfolioLoaded: true,
      };
    }

    case GET_AVAILABLE_BALANCE_FOR_STRATEGY_SUCCESS: {
      return {
        ...state,
        proStrategyAvailableBalanceLoaded: true,
        proStrategyAvailableBalance: action.payload,
      };
    }

    case FETCH_HMM_SUMMARY_SUCCESS: {
      return {
        ...state,
        hmmSummary: action.payload,
        hmmSummaryLoaded: true,
      };
    }

    case FETCH_MONTHLY_REPORTS_SUCCESS: {
      return {
        ...state,
        monthlyReports: action.payload,
        monthlyReportsLoaded: true,
      };
    }

    case FETCH_CREDITS_CALCULATION_SUCCESS: {
      return {
        ...state,
        creditsCalculation: action.payload,
        creditsCalculationLoaded: true,
      };
    }

    case RESET_MONTHLY_REPORT_DETAILS: {
      return {
        ...state,
        monthlyReportDetailsLoaded: false,
        monthlyReportDetails: null,
      };
    }
    case FETCH_MONTHLY_REPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        monthlyReportDetailsLoaded: true,
        monthlyReportDetails: action.payload,
      };
    }
    case GET_CONNECTED_KEYS_SUCCESS: {
      return {
        ...state,
        connectedKeys: action.payload,
        connectedKeysLoaded: true,
      };
    }
    default:
      return state;
  }
};

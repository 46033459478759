import { SHOW_TOASTER, RESET_TOASTER } from './constants';

export const showToaster = (payload) => ({
  type: SHOW_TOASTER,
  data: payload,
});

export const resetToaster = () => ({
  type: RESET_TOASTER,
});

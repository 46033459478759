import { takeLatest, put, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';

import api, { authIP, rootPath } from '../../../api';
import {
  subscribeStrategy,
  getProStrategies,
  getProStrategiesSuccess,
  getProSubscriptionDetails,
  getProSubscriptionDetailsSuccess,
  getProPastSubscriptionDetails,
  getProPastSubscriptionDetailsSuccess,
  unsubscribeStrategy,
  transferFund,
  fetchProUserProfileSuccess,
  updateActiveStatus,
  updateActiveStatusSuccess,
  updateDeployedAmount,
  updateDeployedAmountSuccess,
  getProSummary,
  getProSummarySuccess,
  getProUserPositions,
  getProUserPositionsSuccess,
  updateLeverageMargin,
  updateLeverageMarginSuccess,
  fetchProUsersList,
  fetchProUsersListSuccess,
  fetchProUserProfile,
  getAumHistoryData,
  getAumHistoryDataSuccess,
  getStrategiesTotalReturnsData,
  fetchSipStrategyPortfolio,
  fetchHmmSummary,
  fetchMonthlyReports,
  fetchCreditsCalculation,
  exchangeRefresh,
  downloadDepositTransferHistory,
  fetchMonthlyReportDetails,
  redeemSmartCash,
  investSmartCash,
  getConnectedKeys,
  getProStrategyAvailableBalance,
} from './action';

import { FETCH_PRO_USER_PROFILE, PRO_STRATEGIES, PRO_SUBSCRIPTION_DETAILS } from './types';
import { urls } from './urls';
import store from '../../../store';

function* getProStrategiesReq(action) {
  const { userId } = action.payload;
  const url = urls.getProStrategies({ userId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getProStrategiesSuccess(response.data.data));
    }
  } catch (e) { }
}

function* getProSubscriptionDetailsReq(action) {
  const { userId, live } = action.payload;
  const url = urls.getProSubscriptionDetails({ userId, live });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* getProPastSubscriptionDetailsReq(action) {
  const { userId, live } = action.payload;
  const url = urls.getProSubscriptionDetails({ userId, live });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* transferFundReq(action) {
  const url = urls.transferFund();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.post(url, action.payload));
  });
}

function* subscribeStrategyReq(action) {
  const url = urls.subscribeStrategy();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.post(url, action.payload));
  });
}

function* unsubscribeStrategyReq(action) {
  const { strategyId, userId } = action.payload;
  const url = urls.unsubscribeStrategy({ strategyId, userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url, action.payload));
  });
}

function* fetchProUserProfileReq(action) {
  const { userId } = action.payload;
  const url = urls.fetchProUserProfile({ userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* updateActiveStatusReq(action) {
  const { strategyId, flag, userId } = action.payload;

  const url = urls.updateActiveStatus({ strategyId, flag, userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url));
  });
}

function* updateDeployedAmountReq(action) {
  const { strategyId, userId, amount } = action.payload;

  const url = urls.updateDeployedAmount({ strategyId, userId, amount });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url, action.payload));
  });
}

function* getProSummaryReq(action) {
  const { userId } = action.payload;
  const url = urls.getProSummary({ userId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getProSummarySuccess(response.data.data));
    }
  } catch (e) { }
}

function* getProUserPositionsReq(action) {
  const { strategyId, userId } = action.payload;
  const url = urls.getProUserPositions({ strategyId, userId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getProUserPositionsSuccess(response.data.data));
    }
  } catch (e) { }
}
function* getAumHistoryDataReq(action) {
  const { ssid, userId } = action.payload;
  const url = urls.getAumHistoryData({ ssid, userId });
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getAumHistoryDataSuccess(response.data.data));
    }
  } catch (e) { }
}

function* updateLeverageMarginReq(action) {
  const { strategyId, userId, code, symbols, leverage, marginType } = action.payload;

  const url = urls.updateLeverageMargin({ strategyId, userId, code, symbols, leverage, marginType });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url, action.payload));
  });
}

function* fetchProUsersListReq() {
  const url = urls.fetchProUsersList();
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchProUsersListSuccess(response.data.data));
    }
  } catch (e) { }
}

function* getStrategiesTotalReturnsDataReq(action) {
  const { userId } = action.payload;

  const url = urls.getStrategiesTotalReturnsData({ userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* getConnectedKeysReq(action) {
  const { userID } = action.payload;
  const url = urls.getConnectedKeys({ userID });

  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* getAvailableBalanceForStrategyReq(action) {
  const { userId, keyType } = action.payload;
  const url = urls.fetchProStrategyAvailableBalance({ userId, keyType });

  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* fetchSipStrategyPortfolioReq(action) {
  const { strategyId, userId } = action.payload;

  const url = urls.fetchSipStrategyPortfolio({ strategyId, userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* fetchHmmSummaryReq(action) {
  const { userId } = action.payload;

  const url = urls.fetchHmmSummary({ userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* fetchMonthlyReportsReq(action) {
  const { userId } = action.payload;
  const url = urls.fetchMonthlyReports({ userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* fetchCreditsCalculationReq(action) {
  const { userId } = action.payload;
  const url = urls.fetchCreditsCalculation({ userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* exchangeRefreshReq(action) {
  const { account, userId } = action.payload;
  const url = urls.exchangeRefresh({ account });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url, { userId }));
  });
}

function* downloadDepositTransferHistoryReq(action) {
  const { userId } = action.payload;
  const url = urls.downloadDepositTransferHistory({ userId });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* fetchMonthlyReportDetailsReq(action) {
  const { userId, monthCode } = action?.payload;
  const url = urls.fetchMonthlyReportDetails({ userId, monthCode });
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* redeemSmartCashReq(action) {
  const url = urls.redeemSmartCash();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.post(url, action.payload));
  });
}

function* investSmartCashReq(action) {
  const url = urls.investSmartCash();
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.post(url, action.payload));
  });
}

export function* proSaga() {
  yield takeLatest(getProStrategies, getProStrategiesReq);
  yield takeLatest(getProSubscriptionDetails, getProSubscriptionDetailsReq);
  yield takeLatest(getProPastSubscriptionDetails, getProPastSubscriptionDetailsReq);
  yield takeLatest(subscribeStrategy, subscribeStrategyReq);
  yield takeLatest(unsubscribeStrategy, unsubscribeStrategyReq);
  yield takeLatest(transferFund, transferFundReq);
  yield takeLatest(fetchProUserProfile, fetchProUserProfileReq);
  yield takeLatest(updateActiveStatus, updateActiveStatusReq);
  yield takeLatest(updateDeployedAmount, updateDeployedAmountReq);
  yield takeLatest(getProSummary, getProSummaryReq);
  yield takeLatest(getProUserPositions, getProUserPositionsReq);
  yield takeLatest(updateLeverageMargin, updateLeverageMarginReq);
  yield takeLatest(fetchProUsersList, fetchProUsersListReq);
  yield takeLatest(getAumHistoryData, getAumHistoryDataReq);
  yield takeLatest(getStrategiesTotalReturnsData, getStrategiesTotalReturnsDataReq);
  yield takeLatest(fetchSipStrategyPortfolio, fetchSipStrategyPortfolioReq);
  yield takeLatest(fetchHmmSummary, fetchHmmSummaryReq);
  yield takeLatest(fetchMonthlyReports, fetchMonthlyReportsReq);
  yield takeLatest(fetchCreditsCalculation, fetchCreditsCalculationReq);
  yield takeLatest(exchangeRefresh, exchangeRefreshReq);
  yield takeLatest(downloadDepositTransferHistory, downloadDepositTransferHistoryReq);
  yield takeLatest(fetchMonthlyReportDetails, fetchMonthlyReportDetailsReq);
  yield takeLatest(redeemSmartCash, redeemSmartCashReq);
  yield takeLatest(investSmartCash, investSmartCashReq);
  yield takeLatest(getConnectedKeys, getConnectedKeysReq);
  yield takeLatest(getProStrategyAvailableBalance, getAvailableBalanceForStrategyReq);
}

import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1051;
  & .toaster-icon {
    img {
      display: block;
    }
  }
  & .toaster {
    border-radius: 4px;
    background-color: #f9d3d1;
    padding: 12px 24px 12px 20px;
    color: #911f1b;
    font-weight: 600;
    border: 1px solid #ee6d67;
    &.success {
      color: #006e2e;
      background-color: #def8e9;
      border-color: #73e1a1;
    }
  }
`;

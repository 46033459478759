import { takeLatest, put, call } from 'redux-saga/effects';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';

import api, { authIP, rootPath } from '../../../api';
import {
  createCardSuccess,
  getAllCardsSuccess,
  publishCard,
  publishCardSuccess,
  sendInReview,
  sendInReviewSuccess,
  fetchSuggestions,
  fetchTopicsSuccess,
  fetchAuthorProfileSuccess,
  rejectCardSuccess,
  rejectCard,
  fetchAuthorsList,
  fetchCurrentUserProfileSuccess,
  deleteCardSuccess,
  applyFilters,
  sendBoostCards,
  fetchTagList,
  fetchTagListSuccess,
  createTag,
  addUserToPro,
} from './action';
import { showToaster } from '../../../components/shared/Toaster/action';
import {
  ALLCARDS,
  CREATE_CARD,
  FETCH_TOPICS,
  FETCH_AUTHOR_PROFILE,
  FETCH_CURRENT_USER_PROFILE,
  FETCH_AUTHORS_LIST,
  DELETE_CARD,
} from './types';
import { urls } from './urls';
import store from '../../../store';

function* getAllCards(action) {
  const state = store.getState();
  const url = urls.fetchAllCards(action.payload, state?.login?.role);
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(getAllCardsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchSuggestionsReq(action) {
  const url = urls.fetchSuggestions(action.payload);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.get(url));
  });
}

function* rejectCardReq(action) {
  const url = urls.rejectCard(action.payload);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url));
  });
}

function* sendInReviewReq(action) {
  const url = urls.sendCardInReview(action.payload);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url));
  });
}

function* publishCardReq(action) {
  const { id, notifyRecipients } = action.payload;
  const url = urls.publishCard(id, notifyRecipients);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.put(url));
  });
}

function* createCard(action) {
  const state = store?.getState();
  let { card, userName, carousel, id, type, isAdmin, tagIds } = action.payload;
  let requestBody = {};
  if (carousel) {
    const { cards } = card;
    const url = urls.getImageUrl(isAdmin);
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const cardList = [];
    try {
      yield* cards.map(function* (card) {
        const { image, ...rest } = card;
        let updatedWidgets = card?.widgets;
        if (image && image?.name) {
          let formData = new FormData();
          formData.append('file', image, image.name);
          const response = yield api.post(url, formData, config);
          if (response?.data?.data) {
            updatedWidgets = card?.widgets.map((widget) => {
              if (widget.type === 'IMAGE') {
                widget.imgUrls = [response?.data?.data];
              }
              return widget;
            });
          }
        }
        cardList.push({ ...rest, widgets: updatedWidgets });
      });
      if (id || !isAdmin) {
        requestBody = {
          cards: cardList,
          type,
          tagIds: tagIds,
        };
      } else {
        requestBody = {
          username: userName,
          card: {
            tagIds: tagIds,
            cards: cardList,
            type,
          },
        };
      }
      const postUrl = urls.createCard(id, state?.login?.role);
      const resp = id ? yield api.put(postUrl, requestBody) : yield api.post(postUrl, requestBody);
      if (resp?.data?.message === 'ok') {
        yield put(showToaster({ msg: `Card updated successfully`, timeout: 2000, success: true, refresh: false }));
        yield put(createCardSuccess({ result: id ? 'edit' : 'success', id }));
      }
    } catch (e) {
      yield put(
        showToaster({
          msg: `Something went wrong. Please try again.`,
          timeout: 2000,
          success: false,
          refresh: false,
        }),
      );
      yield put(createCardSuccess('error'));
    }
  } else {
    const { image, data, id, video } = card;
    try {
      if (image && image?.name) {
        let formData = new FormData();
        formData.append('file', image, image.name);
        const url = urls.getImageUrl(isAdmin);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        const response = yield api.post(url, formData, config);
        let updatedWidgets = [];
        if (response?.data?.data) {
          updatedWidgets = data?.widgets.map((widget) => {
            if (widget.type === 'IMAGE') {
              widget.imgUrls = [response?.data?.data];
            }
            return widget;
          });
          if (video && video?.name) {
            let videoformData = new FormData();
            videoformData.append('file', video, video.name);
            const url = urls.getVideoUrl(isAdmin);
            const response = yield api.post(url, { fileName: video.name });
            if (response?.data?.data) {
              const newApi = axios.create({
                baseURL: rootPath,
              });
              const uploadResponse = yield newApi.put(response.data.data.uploadUrl, video, {
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': video.type,
                },
                withCredentials: false,
              });
              if (uploadResponse?.status === 200) {
                updatedWidgets = data?.widgets.map((widget) => {
                  if (widget.type === 'VIDEO') {
                    widget.videoPath = response?.data?.data?.videoPath;
                  }
                  return widget;
                });
                if (id || !isAdmin) {
                  requestBody = {
                    ...data,
                    widgets: updatedWidgets,
                    type,
                  };
                } else {
                  requestBody = {
                    username: userName,
                    card: {
                      ...data,
                      widgets: updatedWidgets,
                      type,
                    },
                  };
                }
                const postUrl = urls.createCard(id, state?.login?.role);
                const resp = id ? yield api.put(postUrl, requestBody) : yield api.post(postUrl, requestBody);
                if (resp?.data?.message === 'ok') {
                  yield put(
                    showToaster({ msg: `Card updated successfully`, timeout: 2000, success: true, refresh: false }),
                  );
                  yield put(createCardSuccess({ result: id ? 'edit' : 'success', id }));
                }
              }
            }
          } else {
            if (id || !isAdmin) {
              requestBody = {
                ...data,
                widgets: updatedWidgets,
                type,
              };
            } else {
              requestBody = {
                username: userName,
                card: {
                  ...data,
                  widgets: updatedWidgets,
                  type,
                },
              };
            }
            const postUrl = urls.createCard(id, state?.login?.role);
            const resp = id ? yield api.put(postUrl, requestBody) : yield api.post(postUrl, requestBody);
            if (resp?.data?.message === 'ok') {
              yield put(
                showToaster({ msg: `Card updated successfully`, timeout: 2000, success: true, refresh: false }),
              );
              yield put(createCardSuccess({ result: id ? 'edit' : 'success', id }));
            }
          }
        }
      } else {
        try {
          if (id || !isAdmin) {
            requestBody = {
              ...data,
              widgets: data?.widgets,
              type,
            };
          } else {
            requestBody = {
              username: userName,
              card: {
                ...data,
                widgets: data.widgets,
                type,
              },
            };
          }
          const postUrl = urls.createCard(id, state?.login?.role);
          const resp = id ? yield api.put(postUrl, requestBody) : yield api.post(postUrl, requestBody);
          if (resp?.data?.message === 'ok') {
            yield put(showToaster({ msg: `Card updated successfully`, timeout: 2000, success: true, refresh: false }));
            yield put(createCardSuccess({ result: id ? 'edit' : 'success', id }));
          }
        } catch (e) {
          yield put(
            showToaster({
              msg: e?.message || `Something went wrong. Please try again.`,
              timeout: 4000,
              success: false,
              refresh: false,
            }),
          );
          yield put(createCardSuccess('error'));
        }
      }
    } catch (e) {
      yield put(
        showToaster({
          msg: e?.message || `Something went wrong. Please try again.`,
          timeout: 4000,
          success: false,
          refresh: false,
        }),
      );
      yield put(createCardSuccess('error'));
    }
  }
}

function* fetchTopics() {
  const url = urls.fetchTopics();
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchTopicsSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchAuthorsListReq(action) {
  const { searchText } = action.payload || {};
  const url = urls.fetchAuthorsList(searchText);
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.get(url));
    });
  } catch (e) {}
}

function* fetchAuthorProfile(action) {
  const { authorId } = action.payload;
  const url = urls.fetchAuthorProfile(authorId);
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchAuthorProfileSuccess(response.data.data));
    }
  } catch (e) {}
}

function* fetchCurrentUserProfile() {
  const url = urls.fetchCurrentUserProfile();
  try {
    const response = yield api.get(url);
    if (response?.data?.data) {
      yield put(fetchCurrentUserProfileSuccess(response.data.data));
    }
  } catch (e) {}
}

function* deleteCards(action) {
  const state = store.getState();
  const { id } = action.payload;
  const url = urls.deleteCard(id, state?.login?.role);
  try {
    const response = yield api.delete(url);
    if (response?.data?.message === 'ok') {
      yield put(deleteCardSuccess({ result: 'edit', id }));
      yield put(showToaster({ msg: `Card deleted successfully`, timeout: 2000, success: true, refresh: false }));
    }
  } catch (e) {
    yield put(
      showToaster({ msg: e?.message ?? `Something went wrong`, timeout: 2000, success: false, refresh: false }),
    );
  }
}

function* applyFiltersReq(action) {
  const { cardId } = action.payload || {};
  const url = urls.applyFilters(cardId);
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.get(url));
    });
  } catch (e) {}
}

function* sendBoostCardsReq(action) {
  let { cardIds } = action.payload || {};
  const url = urls.sendBoostCards();
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.post(url, { cardIds }));
    });
  } catch (e) {}
}

function* fetchTagListReq(action) {
  const { searchText } = action.payload;
  const url = urls.fetchTagList(searchText);
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.get(url));
    });
  } catch (e) {}
}

function* createTagReq(action) {
  const { tag } = action.payload;
  const url = urls.createTag(tag);
  try {
    yield call(implementPromiseAction, action, function* () {
      return yield call(() => api.post(url));
    });
  } catch (e) {}
}

function* addUserToProReq(action) {
  const { userId, accountType } = action.payload;
  const url = urls.addUserToPro(accountType);
  yield call(implementPromiseAction, action, function* () {
    return yield call(() => api.post(url, userId));
  });
}

export function* allCardsSaga() {
  yield takeLatest(ALLCARDS, getAllCards);
  yield takeLatest(CREATE_CARD, createCard);
  yield takeLatest(FETCH_TOPICS, fetchTopics);
  yield takeLatest(FETCH_AUTHORS_LIST, fetchAuthorsList);
  yield takeLatest(FETCH_AUTHOR_PROFILE, fetchAuthorProfile);
  yield takeLatest(FETCH_CURRENT_USER_PROFILE, fetchCurrentUserProfile);
  yield takeLatest(DELETE_CARD, deleteCards);
  yield takeLatest(fetchSuggestions, fetchSuggestionsReq);
  yield takeLatest(rejectCard, rejectCardReq);
  yield takeLatest(sendInReview, sendInReviewReq);
  yield takeLatest(publishCard, publishCardReq);
  yield takeLatest(fetchAuthorsList, fetchAuthorsListReq);
  yield takeLatest(applyFilters, applyFiltersReq);
  yield takeLatest(sendBoostCards, sendBoostCardsReq);
  yield takeLatest(fetchTagList, fetchTagListReq);
  yield takeLatest(createTag, createTagReq);
  yield takeLatest(addUserToPro, addUserToProReq);
}

export const FETCH_TRADER_STRATEGIES = 'trader/FETCH_TRADER_STRATEGIES';
export const FETCH_TRADER_STRATEGIES_SUCCESS = 'trader/FETCH_TRADER_STRATEGIES_SUCCESS';

export const FETCH_TRADER_STRATEGY_INFO = 'trader/FETCH_TRADER_STRATEGY_INFO';
export const FETCH_TRADER_STRATEGY_INFO_SUCCESS = 'trader/FETCH_TRADER_STRATEGY_INFO_SUCCESS';

export const FETCH_TRADERS_ACTIVE_CALLS = 'trader/FETCH_TRADERS_ACTIVE_CALLS';
export const FETCH_TRADERS_ACTIVE_CALLS_SUCCESS = 'trader/FETCH_TRADERS_ACTIVE_CALLS_SUCCESS';

export const FETCH_TRADERS_PAST_CALLS = 'trader/FETCH_TRADERS_PAST_CALLS';
export const FETCH_TRADERS_PAST_CALLS_SUCCESS = 'trader/FETCH_TRADERS_PAST_CALLS_SUCCESS';

export const PRO_STRATEGIES = 'pro/PRO_STRATEGIES';
export const PRO_STRATEGIES_SUCCESS = 'pro/PRO_STRATEGIES_SUCCESS';

export const PRO_SUBSCRIPTION_DETAILS = 'pro/PRO_SUBSCRIPTION_DETAILS';
export const PRO_SUBSCRIPTION_DETAILS_SUCCESS = 'pro/PRO_SUBSCRIPTION_DETAILS_SUCCESS';
export const RESET_PRO_SUBSCRIPTION_DETAILS = 'pro/RESET_PRO_SUBSCRIPTION_DETAILS';

export const PRO_PAST_SUBSCRIPTION_DETAILS = 'pro/PRO_PAST_SUBSCRIPTION_DETAILS';
export const PRO_PAST_SUBSCRIPTION_DETAILS_SUCCESS = 'pro/PRO_PAST_SUBSCRIPTION_DETAILS_SUCCESS';

export const SUBSCRIBE_STRATEGY = 'pro/SUBSCRIBE_STRATEGY';
export const SUBSCRIBE_STRATEGY_SUCCESS = 'pro/SUBSCRIBE_STRATEGY_SUCCESS';

export const UNSUBSCRIBE_STRATEGY = 'pro/UNSUBSCRIBE_STRATEGY';
export const UNSUBSCRIBE_STRATEGY_SUCCESS = 'pro/UNSUBSCRIBE_STRATEGY_SUCCESS';

export const TRANSFER_FUNDS = 'pro/TRANSFER_FUNDS';
export const TRANSFER_FUNDS_SUCCESS = 'pro/TRANSFER_FUNDS_SUCCESS';

export const FETCH_PRO_USER_PROFILE = 'card/FETCH_PRO_USER_PROFILE';
export const FETCH_PRO_USER_PROFILE_SUCCESS = 'card/FETCH_PRO_USER_PROFILE_SUCCESS';

export const UPDATE_ACTIVE_STATUS = 'pro/UPDATE_ACTIVE_STATUS';
export const UPDATE_ACTIVE_STATUS_SUCCESS = 'pro/UPDATE_ACTIVE_STATUS_SUCCESS';

export const UPDATE_DEPLOYED_AMOUNT = 'pro/UPDATE_DEPLOYED_AMOUNT';
export const UPDATE_DEPLOYED_AMOUNT_SUCCESS = 'pro/UPDATE_DEPLOYED_AMOUNT_SUCCESS';

export const GET_PRO_SUMMARY = 'pro/GET_PRO_SUMMARY';
export const GET_PRO_SUMMARY_SUCCESS = 'pro/GET_PRO_SUMMARY_SUCCESS';

export const GET_PRO_USER_POSITIONS = 'pro/GET_PRO_USER_POSITIONS';
export const GET_PRO_USER_POSITIONS_SUCCESS = 'pro/GET_PRO_USER_POSITIONS_SUCCESS';

export const GET_CONNECTED_KEYS = 'pro/GET_CONNECTED_KEYS';
export const GET_CONNECTED_KEYS_SUCCESS = 'pro/GET_CONNECTED_KEYS_SUCCESS';

export const UPDATE_LEVERAGE_MARGIN = 'pro/UPDATE_LEVERAGE_MARGIN';
export const UPDATE_LEVERAGE_MARGIN_SUCCESS = 'pro/UPDATE_LEVERAGE_MARGIN_SUCCESS';

export const FETCH_PRO_USERS_LIST = 'pro/FETCH_PRO_USERS_LIST';
export const FETCH_PRO_USERS_LIST_SUCCESS = 'pro/FETCH_PRO_USERS_LIST_SUCCESS';

export const GET_AUM_HISTORY_DATA = 'pro/GET_AUM_HISTORY_DATA';
export const GET_AUM_HISTORY_DATA_SUCCESS = 'pro/GET_AUM_HISTORY_DATA_SUCCESS';

export const GET_STRATEGIES_TOTAL_RETURNS = 'pro/GET_STRATEGIES_TOTAL_RETURNS';
export const GET_STRATEGIES_TOTAL_RETURNS_SUCCESS = 'pro/GET_STRATEGIES_TOTAL_RETURNS_SUCCESS';

export const GET_AVAILABLE_BALANCE_FOR_STRATEGY = 'pro/GET_AVAILABLE_BALANCE_FOR_STRATEGY';
export const GET_AVAILABLE_BALANCE_FOR_STRATEGY_SUCCESS = 'pro/GET_AVAILABLE_BALANCE_FOR_STRATEGY_SUCCESS';

export const FETCH_SIP_STRATEGY_PORTFOLIO = 'pro/FETCH_SIP_STRATEGY_PORTFOLIO';
export const FETCH_SIP_STRATEGY_PORTFOLIO_SUCCESS = 'pro/FETCH_SIP_STRATEGY_PORTFOLIO_SUCCESS';

export const FETCH_HMM_SUMMARY = 'pro/FETCH_HMM_SUMMARY';
export const FETCH_HMM_SUMMARY_SUCCESS = 'pro/FETCH_HMM_SUMMARY_SUCCESS';

export const FETCH_MONTHLY_REPORTS = 'reports/FETCH_MONTHLY_REPORTS';
export const FETCH_MONTHLY_REPORTS_SUCCESS = 'reports/FETCH_MONTHLY_REPORTS_SUCCESS';

export const FETCH_CREDITS_CALCULATION = 'reports/FETCH_CREDITS_CALCULATION';
export const FETCH_CREDITS_CALCULATION_SUCCESS = 'reports/FETCH_CREDITS_CALCULATION_SUCCESS';

export const EXCHANGE_REFRESH = 'refresh/EXCHANGE_REFRESH';
export const EXCHANGE_REFRESH_SUCCESS = 'refresh/EXCHANGE_REFRESH_SUCCESS';

export const DOWNLOAD_DEPOSIT_HISTORY = 'refresh/DOWNLOAD_DEPOSIT_HISTORY';
export const DOWNLOAD_DEPOSIT_HISTORY_SUCCESS = 'refresh/DOWNLOAD_DEPOSIT_HISTORY_SUCCESS';

export const RESET_MONTHLY_REPORT_DETAILS = 'reports/RESET_MONTHLY_REPORT_DETAILS';

export const FETCH_MONTHLY_REPORT_DETAILS = 'reports/FETCH_MONTHLY_REPORT_DETAILS';
export const FETCH_MONTHLY_REPORT_DETAILS_SUCCESS = 'reports/FETCH_MONTHLY_REPORT_DETAILS_SUCCESS';

export const REDEEM_SMART_CASH = 'redeem/REDEEM_SMART_CASH';
export const REDEEM_SMART_CASH_SUCCESS = 'redeem/REDEEM_SMART_CASH_SUCCESS';

export const INVEST_SMART_CASH = 'invest/INVEST_SMART_CASH_SUCCESS';
export const INVEST_SMART_CASH_SUCCESS = 'invest/INVEST_SMART_CASH_SUCCESS';

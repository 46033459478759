import React, { lazy } from 'react';

import NotFound from '../components/shared/NotFound';

const Distribution = lazy(() => import('../pages/distribution'));
const Home = lazy(() => import('../pages/home'));
const Login = lazy(() => import('../pages/login'));
const Author = lazy(() => import('../pages/home'));
const Stories = lazy(() => import('../pages/stories'));
const Strategies = lazy(() => import('../pages/strategies'));
const SahicoinPro = lazy(() => import('../pages/sahicoin-pro'));
const TraderDashboard = lazy(() => import('../pages/traderdashboard'));
const StrategyDetails = lazy(() => import('../pages/traderdashboard/strategydetails'));
const OrderManagement = lazy(() => import('../pages/ordermanagement'));

const NoMatch = () => {
  return (
    <div>
      <NotFound />
    </div>
  );
};

export const routes = [
  {
    path: '/',
    component: Distribution,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/sahicoin-app',
    component: Home,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
    isLogin: true,
  },
  {
    path: '/profile/:id',
    component: Author,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/sahicoin-pro',
    component: SahicoinPro,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/strategies',
    component: Strategies,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/stories',
    component: Stories,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/traderdashboard',
    component: TraderDashboard,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/traderdashboard/strategydetails',
    component: StrategyDetails,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/ordermanagement',
    component: OrderManagement,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/:notFound',
    component: NoMatch,
  },
];

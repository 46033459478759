export const ALLCARDS = 'cards/ALLCARDS';
export const ALLCARDS_SUCCESS = 'cards/ALLCARDS_SUCCESS';
export const ALLCARDS_FAILURE = 'cards/ALLCARDS_FAILURE';
export const ALLCARDS_RESET = 'cards/ALLCARDS_RESET';
export const SEND_IN_REVIEW = 'cards/SEND_IN_REVIEW';
export const SEND_IN_REVIEW_SUCCESS = 'cards/SEND_IN_REVIEW_SUCCESS';
export const PUBLISH_CARD = 'cards/PUBLISH_CARD';
export const PUBLISH_CARD_SUCCESS = 'cards/PUBLISH_CARD_SUCCESS';
export const CREATE_CARD = 'card/CREATE_CARD';
export const CREATE_CARD_SUCCESS = 'card/CREATE_CARD_SUCCESS';
export const REJECT_CARD = 'card/REJECT_CARD';
export const REJECT_CARD_SUCCESS = 'card/REJECT_CARD_SUCCESS';
export const FETCH_SUGGESTIONS = 'card/FETCH_SUGGESTIONS';
export const FETCH_SUGGESTIONS_SUCCESS = 'card/FETCH_SUGGESTIONS_SUCCESS';
export const FETCH_TOPICS = 'card/FETCH_TOPICS';
export const FETCH_TOPICS_SUCCESS = 'card/FETCH_TOPICS_SUCCESS';
export const FETCH_AUTHOR_PROFILE = 'card/FETCH_AUTHOR_PROFILE';
export const FETCH_AUTHOR_PROFILE_SUCCESS = 'card/FETCH_AUTHOR_PROFILE_SUCCESS';
export const FETCH_AUTHORS_LIST = 'card/FETCH_AUTHORS_LIST';
export const FETCH_AUTHORS_LIST_SUCCESS = 'card/FETCH_AUTHORS_LIST_SUCCESS';
export const FETCH_CURRENT_USER_PROFILE = 'card/FETCH_CURRENT_USER_PROFILE';
export const FETCH_CURRENT_USER_PROFILE_SUCCESS = 'card/FETCH_CURRENT_USER_PROFILE_SUCCESS';
export const DELETE_CARD = 'card/DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'card/DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'card/DELETE_CARD_FAILURE';
export const APPLY_FILTER = 'card/APPLY_FILTER';
export const APPLY_FILTER_SUCCESS = 'card/APPLY_FILTER_SUCCESS';
export const APPLY_FILTER_FAILURE = 'card/APPLY_FILTER_FAILURE';
export const BOOST_CARDS = 'cards/BOOST_CARDS';
export const FETCH_TAG_LIST = 'cards/FETCH_TAG_LIST';
export const FETCH_TAG_LIST_SUCCESS = 'cards/FETCH_TAG_LIST_SUCCESS';
export const CREATE_TAG = 'cards/CREATE_TAG';
export const EDIT_CARD_SUCCESS = 'cards/EDIT_CARD_SUCCESS';
export const ADD_USER_TO_PRO = 'cards/ADD_USER_TO_PRO';
